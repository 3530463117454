<template>
  <div class="channel-account">
    <el-radio-group
      v-model="queryData.status"
      size="medium"
      @change="changeStatus"
    >
      <el-radio-button label="2"
        >结佣中({{ sumInfo.unCommission }})</el-radio-button
      >
      <el-radio-button label="3"
        >已结佣({{ sumInfo.commission }})</el-radio-button
      >
      <el-radio-button label="4"
        >结佣明细({{ sumInfo.commissionSettleCount }})</el-radio-button
      >
    </el-radio-group>
    <!--filter-box-->
    <div class="filter-box mt15">
      <div class="filter">
        <el-input
          :placeholder="
            queryData.status == 4
              ? '房源编号、收/退款单号'
              : '订单编号、房源编号'
          "
          v-model="queryData.keywords"
          @keyup.enter.native="search"
          style="width: 295px;"
          class="mr10 f-search-bar"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
        <el-select
          v-model="queryData.batch"
          placeholder="批次"
          clearable
          class="mr10"
          @change="search"
          style="width: 120px;"
        >
          <el-option
            :value="item"
            :label="item"
            v-for="(item, index) in batchList"
            :key="index"
            @change="search"
          ></el-option>
        </el-select>
        <el-cascader
          placeholder="支付方式"
          :options="payTypeList"
          :props="{
            checkStrictly: true,
            label: 'name'
          }"
          v-model="payType"
          clearable
          class="mr10"
          style="width:130px"
          v-if="queryData.status == 4"
          @change="handlePayType"
        ></el-cascader>
        <el-select
          v-model="queryData.channelName"
          placeholder="渠道"
          filterable
          clearable
          style="width: 135px;"
          class="mr10"
          @change="search"
        >
          <el-option
            :value="item.name"
            :label="item.name"
            v-for="(item, index) in channelList"
            :key="index"
          ></el-option>
        </el-select>
        <el-date-picker
          v-model="date"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          @change="dateChange"
        >
        </el-date-picker>
      </div>
      <div class="actions-btn">
        <el-button icon="iconfont icon-refresh" @click="getData"></el-button>
      </div>
    </div>
    <!--filter-box end-->
    <template v-if="queryData.status == 2 || queryData.status == 3">
      <el-table
        key="table1"
        :data="list"
        class="order-merge-table mt15"
        ref="multipleTable"
        show-summary
        :summary-method="getSummaries"
        :span-method="objectSpanMethod"
        @sort-change="sortChange"
        @selection-change="selectChange"
      >
        <template v-if="queryData.status == 2">
          <el-table-column
            type="selection"
            prop="check"
            key="check"
            width="55"
            align="center"
            class-name="no-border"
            fixed
          >
          </el-table-column>
        </template>
        <el-table-column
          prop="orderNumber"
          key="orderNumber"
          label="签约订单"
          header-align="center"
          class-name="no-border"
          fixed
        >
          <template v-slot="{ row }">
            <el-link type="primary" @click="toOrderDetail(row.orderId)">{{
              row.houseNumber
            }}</el-link>
            <p class="sub">
              {{ row.orderNumber }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="channelName"
          key="channelName"
          label="渠道信息"
          header-align="center"
          fixed
        >
          <template v-slot="{ row }">
            <p>{{ row.channelName }}</p>
            <p class="sub">{{ row.agentName }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="singAmount"
          key="singAmount"
          label="签约金额"
          header-align="center"
          width="110"
        >
          <template v-slot="{ row }">
            <p>¥{{ row.singAmount | toThousands }}</p>
            <p class="sub">优惠 {{ row.discountRate }}</p>
          </template></el-table-column
        >
        <el-table-column label="结佣明细" header-align="center" key="detail">
          <el-table-column
            prop="points"
            label="佣金点位"
            header-align="center"
            class-name="sub-title"
          >
            <template v-slot="{ row }">
              <p>{{ row.points }}%</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="commission"
            label="应结佣金"
            header-align="center"
            class-name="sub-title"
            width="110"
          >
            <template v-slot="{ row }">
              <p>¥{{ row.commission | toThousands }}</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="cashAward"
            label="现金奖"
            header-align="center"
            class-name="sub-title"
            width="110"
          >
            <template v-slot="{ row }">
              <p v-if="row.cashAward">¥{{ row.cashAward | toThousands }}</p>
              <p v-else>-</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="disCommission"
            label="折佣金额"
            header-align="center"
            class-name="sub-title"
            width="110"
          >
            <template v-slot="{ row }">
              <p v-if="!row.flag">¥{{ row.disCommission | toThousands }}</p>
              <p v-else>-</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="marketingAllocation"
            label="渠道分摊"
            header-align="center"
            class-name="sub-title"
            width="110"
          >
            <template v-slot="{ row }">
              <p v-if="!row.flag">
                ¥{{ (row.marketingAllocation || 0) | toThousands }}
              </p>
              <p v-else>-</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="letCount"
            label="小计"
            header-align="center"
            class-name="sub-title"
            width="120"
          >
            <template v-slot="{ row }">
              <p>¥{{ row.letCount | toThousands }}</p>
            </template>
          </el-table-column>
        </el-table-column>
        <template v-if="queryData.status == 2">
          <el-table-column
            prop="channelCommission"
            key="channelCommission"
            label="合计应结"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            header-align="center"
            width="120"
          >
            <template v-slot="{ row }">
              <p>¥{{ row.channelCommission | toThousands }}</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="channelCommissioned"
            key="channelCommissioned"
            label="已结佣金"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            header-align="center"
            width="120"
            v-if="queryData.status == 2"
          >
            <template v-slot="{ row }">
              <p class="orange">¥{{ row.channelCommissioned | toThousands }}</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="channelCommissionSurplus"
            key="channelCommissionSurplus"
            label="剩余待结"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            header-align="center"
            width="120"
            v-if="queryData.status == 2"
          >
            <template v-slot="{ row }">
              <p class="red">
                ¥{{ row.channelCommissionSurplus | toThousands }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="lastPayDate"
            key="lastPayDate"
            label="付款时间"
            header-align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            width="100"
            v-if="queryData.status == 2"
          >
            <template v-slot="{ row }">
              <p>{{ row.lastPayDate || "-" }}</p>
            </template>
          </el-table-column>
        </template>
        <template v-if="queryData.status == 3">
          <el-table-column
            prop="channelCommissioned"
            key="channelCommissioned"
            label="合计结佣"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            header-align="center"
            width="120"
          >
            <template v-slot="{ row }">
              <p>¥{{ row.channelCommissioned | toThousands }}</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="applyDate"
            key="applyDate"
            label="申请日期"
            header-align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            width="100"
            v-if="queryData.status == 3"
          >
            <template v-slot="{ row }">
              <p>
                {{
                  row.applyDate
                    ? $time(row.applyDate).format("YYYY-MM-DD")
                    : "-"
                }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="completeDate"
            key="completeDate"
            label="完成结佣"
            header-align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            width="100"
            v-if="queryData.status == 3"
          >
            <template v-slot="{ row }">
              <p>
                {{
                  row.completeDate
                    ? $time(row.completeDate).format("YYYY-MM-DD")
                    : "-"
                }}
              </p>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </template>
    <template v-if="queryData.status == 4">
      <el-table
        key="table2"
        :data="detailList"
        class="order-merge-table mt15"
        ref="multipleTable"
        @sort-change="sortChangeDetail"
        @selection-change="selectChange"
      >
        <el-table-column
          prop="orderNumber"
          key="orderNumber"
          label="结佣单号"
          align="center"
          width="150"
        >
          <template v-slot="{ row }">
            <el-link type="primary" @click="showChannelAccountDetail(row)">{{
              row.settleNo
            }}</el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="houseNumber"
          key="houseNumber"
          label="房源编号"
          align="center"
        >
          <template v-slot="{ row }">
            <el-link type="primary" @click="toOrderDetail(row.orderId)">{{
              row.houseNumber
            }}</el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="channelName"
          key="channelName"
          label="渠道名称"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="agentName"
          key="agentName"
          label="渠道经纪人"
          align="center"
        >
        </el-table-column>
        <el-table-column label="结佣单信息" header-align="center" key="detail2">
          <el-table-column
            label="支付方式"
            header-align="center"
            class-name="sub-title"
          >
            <template v-slot="{ row }">
              <span v-if="row.paymentType === 1">
                POS机<span v-if="row.payWayInfo"
                  >/{{ row.payWayInfo.name }}</span
                >
              </span>
              <span v-else>{{ payTypeMaps[row.paymentType] || "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="支付账户"
            header-align="center"
            class-name="sub-title"
          >
            <template v-slot="{ row }">
              <span v-if="row.payWayInfo">
                {{
                  row.paymentType === 1
                    ? `终端号${row.payWayInfo.clientNo}`
                    : row.payWayInfo.name
                }}
              </span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column
            label="交易单号"
            prop="transactionNo"
            header-align="center"
            class-name="sub-title"
          >
            <template v-slot="{ row }">
              <p>{{ row.transactionNo || "-" }}</p>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="totalAmount"
          key="totalAmount"
          label="结佣金额"
          sortable="custom"
          :sort-orders="['ascending', 'descending']"
          align="center"
          width="100"
        >
          <template v-slot="{ row }">
            <p class="orange">¥{{ row.totalAmount | toThousands }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="paymentDate"
          key="paymentDate"
          label="付款日期"
          align="center"
          sortable="custom"
          :sort-orders="['ascending', 'descending']"
          width="100"
        >
          <template v-slot="{ row }">
            <p>{{ row.paymentDate || "-" }}</p>
          </template>
        </el-table-column>
        <el-table-column
          key="action"
          label="相关操作"
          align="center"
          width="100"
        >
          <template v-slot="{ row }">
            <el-link type="primary" class="mr10" @click="edit(row)"
              >编辑</el-link
            >
            <el-link type="danger" @click="del(row.settleId)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="table-count">
        合计结佣金额
        <strong>
          ¥{{ sumInfo.channelCommissionedAmount | toThousands }}
        </strong>
      </div>
    </template>
    <div class="check-all" v-if="queryData.status == 2 && list.length">
      <el-checkbox
        v-model="isCheckAll"
        :indeterminate="indeterminate"
        @change="checkAll"
        >{{ indeterminate ? "选择" : "全选" }}</el-checkbox
      >
    </div>
    <div class="footer-bar mt10">
      <div class="action">
        <template v-if="queryData.status == 2 && checkList.length">
          <el-button
            type="primary"
            class="mr10"
            v-if="checkList.length == 1"
            @click="createPaymentVisible = true"
          >
            创建结佣
          </el-button>
          <el-button type="info" @click="delChannelPaymentBatch">
            批量删除
          </el-button>
        </template>
      </div>
      <f-pagination
        :page-num.sync="queryData.pageNum"
        :page-size.sync="queryData.pageSize"
        :total="total"
        :fixed="false"
        @change="getChannelPaymentList"
      ></f-pagination>
    </div>
    <!-- 创建结佣 -->
    <edit-commission-settlement
      :visible.sync="createPaymentVisible"
      :estateId="estateId"
      :id="curRow.id"
      :settleId="curRow.settleId"
      :orderId="curRow.orderId"
      :success="handleEditSuccess"
    >
    </edit-commission-settlement>
    <!-- 结佣详情 -->
    <channel-account-detail
      ref="channelAccountDetail"
      :visible.sync="channelAccountDetailVisible"
      :estateId="estateId"
      :id="curRow.settleId"
      :orderId="curRow.orderId"
      @del="del(curRow.settleId)"
      @edit="edit(curRow)"
    ></channel-account-detail>
  </div>
</template>

<script>
import EditCommissionSettlement from "../../../detail/_components/channelAccount/editCommissionSettlement";
import ChannelAccountDetail from "./_components/detail";
import { toThousands } from "@/utils/index";
import {
  getChannelPaymentList,
  delChannelPaymentBatch,
  getChannelPaymentCounts,
  geCommissionChannelList,
  commissionSettleList,
  deleteSettlementCommission,
  paymentType
} from "@/api/signedOrder";
export default {
  components: { EditCommissionSettlement, ChannelAccountDetail },
  props: ["estateId", "batchList"],
  data() {
    return {
      date: "",
      queryData: {
        batch: "", // 批次
        channelName: "", //渠道id ,
        end: "", // 结束日期 ,
        estateId: "", //楼盘id ,
        keywords: "", // 订单编号、房源编号 ,
        pageNum: 1, //,
        pageSize: 30, //,
        start: "", // 开始日期 ,
        status: 2 // 结佣状态(1: 预结佣, 2: 结佣中, 3: 已结佣)
      },
      orderByInfo: {}, // 佣金列表排序
      orderByInfoDetail: {}, // 佣金明细列表排序
      // 统计
      sumInfo: {
        baseCommission: 0,
        commission: 0,
        extCommission: 0,
        mergeCommission: 0,
        unCommission: 0
      },
      list: [], // 结佣列表
      detailList: [], // 结佣明细列表
      total: 0,
      isCheckAll: false, // 自定义全选
      indeterminate: false, // 自定义半选状态
      checkList: [], // 选中的行
      channelList: [], // 渠道列表
      createPaymentVisible: false, // 是否显示创建结佣弹窗
      channelAccountDetailVisible: false, // 是否显示结佣单详情弹窗
      curRow: {},
      payType: null, // 收款方式
      payTypeList: [],
      payParams: {
        // 结佣明细，支付方式查询参数
        payMethod: null,
        name: null,
        mchNo: null
      },
      payTypeMaps: {
        0: "银行转账",
        1: "POS机",
        2: "现金支付",
        3: "微信支付",
        4: "支付宝",
        5: "定金转入"
      }
    };
  },
  watch: {
    estateId: {
      immediate: true,
      handler(id) {
        // 楼盘id变化时，重新获取数据
        if (id) {
          this.queryData.estateId = id;
          // 清空上一个楼盘的搜索条件
          this.queryData.batch = "";
          this.queryData.channelName = "";
          this.payType = "";
          this.payParams.payMethod = null;
          this.payParams.name = null;
          this.payParams.mchNo = null;
          this.getChannelList();
          this.getPaymentType();
          this.getData();
        }
      }
    }
  },
  methods: {
    toThousands,
    getSummaries({ columns }) {
      let propMaps = {
        singAmount: "signAmount",
        commission: "commissionAmount",
        cashAward: "cashwardAmount",
        disCommission: "discountCommissionAmount",
        marketingAllocation: "marketingAllocation",
        letCount: "channelCommissionAmount",
        channelCommission: "channelCommissionAmount",
        channelCommissioned: "channelCommissionedAmount",
        channelCommissionSurplus: "channelCommissionSurplusAmount"
      };
      // 合计
      const sums = [];
      columns.forEach((column, index) => {
        if (["check", "orderNumber"].includes(column.property)) {
          sums[index] = "";
        } else if (["channelName"].includes(column.property)) {
          sums[index] = "合计";
        } else if (
          ["points", "lastPayDate", "completeDate", "applyDate"].includes(
            column.property
          )
        ) {
          sums[index] = "-";
        } else {
          sums[index] = `￥${this.toThousands(
            this.sumInfo[propMaps[column.property]]
          )}`;
        }
      });
      return sums;
    },
    // 进入订单详情
    toOrderDetail(id) {
      // 打开新浏览器tab
      this.$openWindow(`/distribution/appManage/signedOrder/${id}`);
    },
    // 结佣单详情
    showChannelAccountDetail(row) {
      this.curRow = row;
      this.channelAccountDetailVisible = true;
    },
    // 切换状态，筛选数据【结佣中】【已结佣】【结佣明细】
    changeStatus() {
      this.channelAccountDetailVisible = false; // 结佣顶部tab切换时关闭结佣明细详情
      this.search();
    },
    // 删除结佣明细
    async del(id) {
      this.$confirm(`删除后无法恢复，确定要删除？`, "删除结佣明细", {
        type: "warning",
        confirmButtonClass: "el-button--danger"
      })
        .then(async () => {
          const res = await deleteSettlementCommission({ id });
          if (res) {
            this.$showSuccess("操作成功");
            this.getData(); // 更新结佣明细列表
            this.channelAccountDetailVisible = false; // 删除成功后，关闭收款单详情drawer
          }
        })
        .catch(() => {});
    },
    // 编辑结佣
    edit(row) {
      this.curRow = row;
      this.createPaymentVisible = true;
    },
    // 编辑收款回调
    handleEditSuccess() {
      this.getData();
      if (this.channelAccountDetailVisible && this.curRow.id) {
        this.$refs.channelAccountDetail.commissionSettleInfo(
          this.curRow.settleId
        ); // 刷新结算单详情
      }
    },
    search() {
      this.queryData.pageNum = 1;
      this.getData();
    },
    getData() {
      this.getChannelPaymentCounts();
      if (this.queryData.status == 4) {
        this.commissionSettleList(); // 更新结佣明细列表
      } else {
        this.getChannelPaymentList(); // 更新结佣列表
      }
    },
    async getPaymentType() {
      // 获取收款方式
      const res = await paymentType(this.estateId);
      if (res) {
        this.payTypeList = res;
      }
    },
    handlePayType(arr) {
      // 设置收款方式
      this.payParams.payMethod = arr[0] || null;
      this.payParams.name = arr[1] || null;
      this.payParams.mchNo = arr[2] || null;
      this.search();
    },
    // 批量删除
    async delChannelPaymentBatch() {
      this.$confirm(`删除后无法恢复结佣记录，确定要删除？`, "删除结佣", {
        type: "warning",
        confirmButtonClass: "el-button--danger"
      })
        .then(async () => {
          const res = await delChannelPaymentBatch({
            orderIds: this.checkList.map(item => item.orderId)
          });
          if (res) {
            this.$showSuccess("操作成功");
            this.getData();
          }
        })
        .catch(() => {});
    },
    // 获取渠道结佣列表
    async getChannelPaymentList() {
      const res = await getChannelPaymentList({
        ...this.queryData,
        ...this.orderByInfo
      });
      if (res) {
        this.total = res.total;
        let result;
        this.list = [];
        this.list = res.list.reduce((arr, row) => {
          arr.push({
            ...row,
            points: row.basePoints,
            commission: row.baseCommission,
            cashAward: row.baseCashAward,
            letCount: row.letCount
          });
          if (row.extPoints) {
            arr.push({
              ...row,
              points: row.extPoints,
              commission: row.extCommission,
              cashAward: row.extCashAward,
              letCount: row.extLetCount,
              disCommission: null,
              marketingAllocation: null,
              extPoints: null,
              flag: true // 标识该行的数据是自动生成的
            });
          }
          return arr;
        }, []);
      }
    },
    // 结佣明细
    async commissionSettleList() {
      let params = {
        ...this.queryData,
        ...this.orderByInfoDetail,
        ...this.payParams
      };
      delete params.status;
      const res = await commissionSettleList(params);
      if (res) {
        this.total = res.total;
        this.detailList = res.list;
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      let spanFields = [
        "check",
        "orderNumber",
        "channelName",
        "singAmount",
        "channelCommission",
        "channelCommissioned",
        "channelCommissionSurplus",
        "lastPayDate",
        "applyDate",
        "completeDate",
        "inDate"
      ];
      // 当前字段是否在可能合并的字段列表中
      let spanField = spanFields.includes(column.property);
      if (row.extPoints) {
        // 如果是在源数据行中， 且需要合并行则返回[2,1], 普通字段正常[1,1]占一格
        return spanField ? [2, 1] : [1, 1];
      } else if (row.flag && spanField) {
        // 如果是派生的行中， 刚将可合并的字段行占0格 (不错位)
        return [0, 1];
      } else {
        return [1, 1];
      }
    },
    // 获取渠道结佣统计
    async getChannelPaymentCounts() {
      let params = Object.assign({}, this.queryData);
      if (this.queryData.status == 4) {
        delete params.status;
      }
      const res = await getChannelPaymentCounts(params);
      if (res) {
        this.sumInfo = res;
      }
    },
    // 获取渠道列表
    async getChannelList() {
      const res = await geCommissionChannelList({
        estateId: this.estateId
      });
      if (res) {
        this.channelList = res.filter(item => item);
      }
    },
    dateChange(val) {
      // 日期
      let [start, end] = val || [];
      Object.assign(this.queryData, {
        start,
        end
      });
      this.search();
    },
    selectChange(selection) {
      // 选中行发生变化时
      let checkAbleCount = this.list.length; // 可选条数
      let checkedCount = selection.length; // 选中的条数

      // 设置自定义全选的选中状态
      if (checkedCount == checkAbleCount) {
        this.isCheckAll = true; // 全选
        this.indeterminate = false; // 半选
      } else {
        this.isCheckAll = false; // 全选
        this.indeterminate = Boolean(checkedCount); // 半选
      }
      // 获取选中的行
      this.checkList = selection;
      if (this.checkList.length == 1) {
        this.curRow = this.checkList[0];
      }
    },
    checkAll(val) {
      // 自定义全选
      this.$refs.multipleTable.toggleAllSelection();
    },
    sortChange({ prop, order }) {
      // sort 1 合计应结 2 已结 3 剩余待结 4 付款时间 5合计已结 6申请日期 7完成日期 ,
      // sortType 1 desc 0 asc
      let sortMap = {
        channelCommission: 1,
        channelCommissioned: this.queryData.status == 2 ? 2 : 5, // 2 待结佣-已结佣金 5 已结佣-合计结佣
        channelCommissionSurplus: 3,
        lastPayDate: 4,
        applyDate: 6,
        completeDate: 7
      };
      this.orderByInfo = {
        sort: sortMap[prop],
        sortType: order == "descending" ? 1 : 0
      };
      this.getChannelPaymentList();
    },
    sortChangeDetail({ prop, order }) {
      // sort 金额10 日期20
      // sortType 1 desc 0 asc
      const sortMap = {
        totalAmount: 10,
        paymentDate: 20
      };
      this.orderByInfoDetail = {
        sort: sortMap[prop],
        sortType: order == "descending" ? 1 : 0
      };
      this.commissionSettleList();
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../../_styles/house.scss";
.filter {
  ::v-deep .el-date-editor {
    margin-left: -1px;
    width: 230px;
    border-radius: 0 4px 4px 0;
  }
}
.channel-account {
  .check-all {
    height: 58px;
    display: flex;
    align-items: center;
    margin-top: -58px;
    padding-left: 20px;
    position: relative;
    z-index: 99;
  }
  ::v-deep {
    .el-table {
      &.order-merge-table th > .cell {
        padding-left: 10px;
      }
      .cell {
        p {
          font-size: 14px;
          color: #333;
        }
        .sub {
          padding-top: 2px;
          font-size: 13px;
          line-height: 20px;
          height: 20px;
          color: #999;
        }
      }
      thead.is-group th {
        background-color: #f2f2f2;
        &.sub-title {
          background-color: rgba(247, 247, 254, 1);
          font-weight: normal;
        }
      }
      .el-table__footer-wrapper,
      .el-table__fixed-footer-wrapper {
        .cell {
          display: flex;
          align-items: center;
          font-weight: bold;
          height: 32px;
          line-height: 16px;
        }
        td.el-table__cell {
          background-color: #f2f2f2;
        }
        td.sub-title {
          .cell {
            color: #999;
          }
        }
        td.no-border {
          border-right-color: transparent;
        }
      }
    }
  }
  .sub-title .cell p {
    color: #999;
  }
}
.table-count {
  text-align: right;
  strong {
    font-size: 18px;
    color: $orange;
  }
  padding: 15px 30px 15px 21px;
}
.orange {
  color: $orange !important;
}
.red {
  color: $red !important;
}
</style>
