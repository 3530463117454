var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "marketing"
  }, [_c('div', {
    staticClass: "filter-box"
  }, [_c('div', {
    staticClass: "filter"
  }, [_c('el-input', {
    staticClass: "f-search-bar",
    attrs: {
      "placeholder": "订单编号、房源编号"
    },
    nativeOn: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      }
    },
    model: {
      value: _vm.queryData.keyword,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "keyword", $$v);
      },
      expression: "queryData.keyword"
    }
  }, [_c('el-button', {
    attrs: {
      "slot": "append"
    },
    on: {
      "click": _vm.search
    },
    slot: "append"
  }, [_vm._v("搜索")])], 1), _c('el-select', {
    attrs: {
      "placeholder": "奖励对象",
      "clearable": ""
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.rewardObj,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "rewardObj", $$v);
      },
      expression: "queryData.rewardObj"
    }
  }, [_c('el-option', {
    attrs: {
      "value": "0",
      "label": "客户"
    }
  }), _c('el-option', {
    attrs: {
      "value": "1",
      "label": "渠道经纪人"
    }
  })], 1), _c('el-select', {
    attrs: {
      "placeholder": "渠道",
      "filterable": "",
      "clearable": ""
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.mechanismName,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "mechanismName", $$v);
      },
      expression: "queryData.mechanismName"
    }
  }, _vm._l(_vm.channelList, function (item, index) {
    return _c('el-option', {
      key: index,
      attrs: {
        "value": item.name,
        "label": item.name
      }
    });
  }), 1), _c('el-date-picker', {
    attrs: {
      "type": "daterange",
      "range-separator": "-",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      "format": "yyyy-MM-dd",
      "value-format": "yyyy-MM-dd"
    },
    on: {
      "change": _vm.dateChange
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1), _c('div', {
    staticClass: "actions-btn"
  }, [_c('el-button', {
    attrs: {
      "icon": "iconfont icon-refresh"
    },
    on: {
      "click": _vm.refresh
    }
  })], 1)]), _c('el-table', {
    staticClass: "mt15",
    attrs: {
      "border": "",
      "data": _vm.list
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "签约订单"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c('el-link', {
          attrs: {
            "type": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.toOrderDetail(row.orderId);
            }
          }
        }, [_vm._v(_vm._s(row.houseNumber))]), _c('p', {
          staticClass: "sub"
        }, [_vm._v(_vm._s(row.orderCode))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "渠道信息"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c('p', [_vm._v(_vm._s(row.mechanismName))]), _c('p', {
          staticClass: "sub"
        }, [_vm._v(_vm._s(row.agentUser))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "成交客户"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c('p', [_vm._v(_vm._s(row.customerName))]), _c('p', {
          staticClass: "sub"
        }, [_vm._v(_vm._s(_vm._f("formatPhone")(row.customerPhone)))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "signDate",
      "label": "签约日期",
      "sortable": "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [row.status !== 1 ? [_c('p', [_vm._v(_vm._s(row.signDate))]), _c('p', {
          staticClass: "sub",
          class: [{
            10: 'blue',
            15: 'orange',
            20: 'green'
          }[row.status]]
        }, [_vm._v(" " + _vm._s(row.statusStr === "回款中" ? "下次回款" : row.statusStr) + " " + _vm._s(row.nextPaymentDate) + " ")])] : [_c('p', [_vm._v("-")]), _c('p', {
          staticClass: "sub"
        })]];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "奖励对象"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c('p', [_vm._v(_vm._s(row.rewardObj === 0 ? "客户" : "经纪人"))]), _c('p', {
          staticClass: "sub"
        })];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "奖励内容"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c('p', [_vm._v(_vm._s(row.rewardContent))]), _c('p', {
          staticClass: "sub"
        })];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "marketingCost",
      "label": "营销成本",
      "sortable": "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_c('p', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(row.marketingCost)))]), _c('p', {
          staticClass: "sub"
        })];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "companyShare",
      "label": "公司分摊",
      "sortable": "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_c('p', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(row.companyShare)))]), _c('p', {
          staticClass: "sub"
        })];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "channelShare",
      "label": "渠道分摊",
      "sortable": "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [_c('p', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(row.channelShare)))]), _c('p', {
          staticClass: "sub"
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "table-count"
  }, [_vm.sumInfo.marketingCost ? _c('div', [_c('span', [_vm._v("合计营销成本"), _c('strong', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.sumInfo.marketingCost)))])]), _c('span', [_vm._v(" 其中，公司分摊 "), _c('strong', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.sumInfo.companyShare)))])]), _c('span', [_vm._v(" 渠道分摊 "), _c('strong', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.sumInfo.channelShare)))])])]) : _vm._e()]), _c('div', {
    staticClass: "footer-bar mt10"
  }, [_c('div', {
    staticClass: "action"
  }), _c('f-pagination', {
    attrs: {
      "page-num": _vm.queryData.pageNum,
      "page-size": _vm.queryData.pageSize,
      "total": _vm.total,
      "fixed": false
    },
    on: {
      "update:pageNum": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:page-num": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:pageSize": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "update:page-size": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "change": _vm.getMarketingList
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }