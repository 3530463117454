<template>
  <div class="marketing">
    <!--filter-box-->
    <div class="filter-box">
      <div class="filter">
        <el-input
          placeholder="订单编号、房源编号"
          v-model="queryData.keyword"
          @keyup.enter.native="search"
          class="f-search-bar"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
        <el-select
          v-model="queryData.rewardObj"
          placeholder="奖励对象"
          clearable
          @change="search"
        >
          <el-option value="0" label="客户"></el-option>
          <el-option value="1" label="渠道经纪人"></el-option>
        </el-select>
        <el-select
          v-model="queryData.mechanismName"
          placeholder="渠道"
          filterable
          clearable
          @change="search"
        >
          <el-option
            :value="item.name"
            :label="item.name"
            v-for="(item, index) in channelList"
            :key="index"
          ></el-option>
        </el-select>
        <el-date-picker
          v-model="date"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          @change="dateChange"
        >
        </el-date-picker>
      </div>
      <div class="actions-btn">
        <el-button icon="iconfont icon-refresh" @click="refresh"></el-button>
      </div>
    </div>
    <!--filter-box end-->
    <el-table border :data="list" class="mt15" @sort-change="sortChange">
      <el-table-column label="签约订单">
        <template v-slot="{ row }">
          <el-link type="primary" @click="toOrderDetail(row.orderId)">{{
            row.houseNumber
          }}</el-link>
          <p class="sub">{{ row.orderCode }}</p>
        </template>
      </el-table-column>
      <el-table-column label="渠道信息">
        <template v-slot="{ row }">
          <p>{{ row.mechanismName }}</p>
          <p class="sub">{{ row.agentUser }}</p>
        </template>
      </el-table-column>
      <el-table-column label="成交客户">
        <template v-slot="{ row }">
          <p>{{ row.customerName }}</p>
          <p class="sub">{{ row.customerPhone | formatPhone }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="signDate" label="签约日期" sortable="custom">
        <template v-slot="{ row }">
          <template v-if="row.status !== 1">
            <p>{{ row.signDate }}</p>
            <p
              class="sub"
              :class="[{ 10: 'blue', 15: 'orange', 20: 'green' }[row.status]]"
            >
              {{ row.statusStr === "回款中" ? "下次回款" : row.statusStr }}
              {{ row.nextPaymentDate }}
            </p>
          </template>
          <template v-else>
            <p>-</p>
            <p class="sub"></p>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="奖励对象">
        <template v-slot="{ row }">
          <p>{{ row.rewardObj === 0 ? "客户" : "经纪人" }}</p>
          <p class="sub"></p>
        </template>
      </el-table-column>
      <el-table-column label="奖励内容">
        <template v-slot="{ row }">
          <p>{{ row.rewardContent }}</p>
          <p class="sub"></p>
        </template>
      </el-table-column>
      <el-table-column prop="marketingCost" label="营销成本" sortable="custom">
        <template v-slot="{ row }">
          <p>¥{{ row.marketingCost | toThousands }}</p>
          <p class="sub"></p>
        </template>
      </el-table-column>
      <el-table-column prop="companyShare" label="公司分摊" sortable="custom">
        <template v-slot="{ row }">
          <p>¥{{ row.companyShare | toThousands }}</p>
          <p class="sub"></p>
        </template>
      </el-table-column>
      <el-table-column prop="channelShare" label="渠道分摊" sortable="custom">
        <template v-slot="{ row }">
          <p>¥{{ row.channelShare | toThousands }}</p>
          <p class="sub"></p>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-count">
      <div v-if="sumInfo.marketingCost">
        <span
          >合计营销成本<strong
            >¥{{ sumInfo.marketingCost | toThousands }}</strong
          ></span
        >
        <span>
          其中，公司分摊
          <strong>¥{{ sumInfo.companyShare | toThousands }}</strong>
        </span>
        <span>
          渠道分摊
          <strong>¥{{ sumInfo.channelShare | toThousands }}</strong>
        </span>
      </div>
    </div>
    <div class="footer-bar mt10">
      <div class="action"></div>
      <f-pagination
        :page-num.sync="queryData.pageNum"
        :page-size.sync="queryData.pageSize"
        :total="total"
        :fixed="false"
        @change="getMarketingList"
      ></f-pagination>
    </div>
  </div>
</template>

<script>
import {
  getMarketingList,
  getMarketingCounts,
  getOrderConditionChannel
} from "@/api/signedOrder";
export default {
  props: ["estateId"],
  data() {
    return {
      date: "",
      queryData: {
        endDate: "", //,
        estateIds: [], //项目ID ,
        keyword: "", //: keyword ,
        mechanismName: "", //: 渠道/机构 ,
        pageNum: 1, //
        pageSize: 30, //
        rewardObj: "", //: 奖励对象 0-客户奖励 1-渠道经纪人 = ['0', '1']stringEnum:"0", "1",
        sortFieldNo: 1, //: 排序字段：1：默认排序 2：签约日期 3：营销成本 4：公司分摊 5：渠道分摊 ,
        sortType: 0, //: 排序 : 0 desc 1 asc ,
        startDate: "" //
      },
      list: [],
      total: 0,
      channelList: [], // 渠道列表
      sumInfo: {
        channelShare: "", // 渠道分摊 ,
        companyShare: "", // 公司分摊 ,
        marketingCost: "" //营销成本
      }
    };
  },
  watch: {
    estateId: {
      immediate: true,
      handler(id) {
        // 楼盘id变化时，重新获取数据
        if (id) {
          this.queryData.estateIds = [id];
          this.getMarketingList();
          this.getMarketingCounts();
          this.getChannelList();
        }
      }
    }
  },
  methods: {
    refresh() {
      this.getMarketingList();
      this.getMarketingCounts();
    },
    // 进入订单详情
    toOrderDetail(id) {
      // 打开新浏览器tab
      this.$openWindow(`/distribution/appManage/signedOrder/${id}`);
    },
    search() {
      this.queryData.pageNum = 1;
      this.getMarketingList();
      this.getMarketingCounts();
    },
    // 获取营销活动列表
    async getMarketingList() {
      const res = await getMarketingList(this.queryData);
      if (res) {
        this.list = res.list;
        this.total = res.total;
      }
    },
    // 获取营销活动统计
    async getMarketingCounts() {
      const res = await getMarketingCounts(this.queryData);
      if (res) {
        this.sumInfo = res;
      }
    },
    // 获取渠道列表
    async getChannelList() {
      const res = await getOrderConditionChannel({ estateId: this.estateId });
      if (res) {
        this.channelList = res.filter(item => item);
      }
    },
    dateChange(val) {
      // 日期
      let [start, end] = val || [];
      this.queryData.startDate = start;
      this.queryData.endDate = end;
      this.search();
    },
    sortChange({ prop, order }) {
      // 排序
      //sortFieldNo (integer, optional): 排序字段：1：默认排序 2：签约日期 3：营销成本 4：公司分摊 5：渠道分摊 ,
      //sortType (integer, optional): 排序 : 0
      const propMap = {
        signDate: 2,
        marketingCost: 3,
        companyShare: 4,
        channelShare: 5
      };
      const orderMap = {
        ascending: 0,
        descending: 1
      };
      this.queryData.sortFieldNo = propMap[prop];
      this.queryData.sortType = orderMap[order];
      this.getMarketingList();
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../_styles/house.scss";
.marketing {
  ::v-deep {
    .filter {
      .el-input {
        width: 295px;
        margin-right: 10px;
      }
      .el-select {
        margin-right: 10px;
        width: 135px;
        .el-input {
          width: 100%;
        }
      }
      .el-date-editor {
        margin-left: -1px;
        width: 230px;
      }
    }
    .el-table {
      .cell {
        p {
          font-size: 14px;
          color: #333;
        }
        .sub {
          padding-top: 2px;
          font-size: 13px;
          line-height: 20px;
          height: 20px;
          color: #999;
          &.orange {
            color: $orange;
          }
          &.blue {
            color: $blue;
          }
          &.green {
            color: $green;
          }
        }
      }
    }
    .el-table td {
      vertical-align: top;
    }
    .table-count {
      strong {
        color: #333;
      }
    }
  }
}
</style>
