import { render, staticRenderFns } from "./profitCalc.vue?vue&type=template&id=6a87feb0&scoped=true"
import script from "./profitCalc.vue?vue&type=script&lang=js"
export * from "./profitCalc.vue?vue&type=script&lang=js"
import style0 from "./profitCalc.vue?vue&type=style&index=0&id=6a87feb0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a87feb0",
  null
  
)

export default component.exports