import { render, staticRenderFns } from "./signedOrder.vue?vue&type=template&id=775bc453&scoped=true"
import script from "./signedOrder.vue?vue&type=script&lang=js"
export * from "./signedOrder.vue?vue&type=script&lang=js"
import style0 from "./signedOrder.vue?vue&type=style&index=0&id=775bc453&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "775bc453",
  null
  
)

export default component.exports