<template>
  <div class="house-wrapper">
    <estate-sider class="mr20" @refresh="setEstateId"></estate-sider>
    <div class="content">
      <el-tabs type="card" class="fm-tabs-card" v-model="activeName">
        <el-tab-pane
          v-for="(item, index) in tabs"
          :label="item.name"
          :name="`${index}`"
          :key="item.component"
        >
          <component
            :is="tabs[activeName].component"
            :estateId="estateId"
            :batchList="batchList"
          ></component>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import subscriptionOrder from "./_components/subscriptionOrder";
import signedOrder from "./_components/signedOrder";
import paymentPlan from "./_components/paymentPlan";
import financial from "./_components/financial";
import Marketing from "./_components/marketing";
import ChannelAccount from "./_components/channelAccount";
import ProfitCalc from "./_components/profitCalc";
import EstateSider from "../../_components/estateSider";
import { saleControlFilterBatch } from "@/api/saleControl";
export default {
  components: {
    EstateSider,
    Marketing,
    ChannelAccount,
    ProfitCalc,
    subscriptionOrder,
    signedOrder,
    paymentPlan,
    financial
  },
  data() {
    return {
      tabs: [
        {
          name: "认购订单",
          component: "subscriptionOrder"
        },
        {
          name: "签约订单",
          component: "signedOrder"
        },
        {
          name: "回款计划",
          component: "paymentPlan"
        },
        {
          name: "财务对账",
          component: "financial"
        },
        {
          name: "渠道结佣",
          component: "ChannelAccount"
        },
        {
          name: "营销活动",
          component: "Marketing"
        }
        // {
        //   name: "利润核算",
        //   component: "ProfitCalc"
        // }
      ],
      activeName: "0",
      estateId: null,
      batchList: [] // 批次列表
    };
  },
  methods: {
    setEstateId(estateId) {
      // 选择楼盘，查询楼盘下的相关订单信息
      this.estateId = estateId;
      this.getBatchList();
    },
    async getBatchList() {
      // 获取批次列表
      let params = {
        estateId: this.estateId
      };
      const res = await saleControlFilterBatch(params);
      if (res) {
        this.batchList = res;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../_styles/house.scss";
::v-deep {
  .el-tabs__content {
    height: calc(100% - 40px);
    overflow-y: auto;
  }
}
</style>
