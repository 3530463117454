var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "channel-account"
  }, [_c('div', {
    staticClass: "filter-box mt15"
  }, [_c('div', {
    staticClass: "filter"
  }, [_c('el-date-picker', {
    attrs: {
      "type": "monthrange",
      "align": "right",
      "range-separator": "至",
      "start-placeholder": "开始月份",
      "end-placeholder": "结束月份",
      "value-format": "yyyy-MM-dd"
    },
    on: {
      "change": _vm.dateChange
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1)]), _c('el-table', {
    ref: "multipleTable",
    staticClass: "mt15",
    attrs: {
      "data": _vm.list,
      "border": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "签约订单"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c('el-link', {
          attrs: {
            "type": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.toOrderDetail(row.id);
            }
          }
        }, [_vm._v(_vm._s(row.houseNumber))]), _c('p', {
          staticClass: "sub"
        }, [_vm._v(_vm._s(row.orderCode))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "渠道信息"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c('p', [_vm._v(_vm._s(row.mechanismName))]), _c('p', {
          staticClass: "sub"
        }, [_vm._v(_vm._s(row.agentUser))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "签约/结佣",
      "prop": "orderDateDesc",
      "sortable": "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c('p', [_vm._v(_vm._s(row.orderDateDesc))]), _c('p', {
          staticClass: "sub"
        }, [_vm._v(_vm._s(row.commissionedDateDesc || "-"))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "成交总额",
      "width": "120px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c('p', [_vm._v(_vm._s(_vm._f("toThousands")(row.totalAmount)))]), _c('p', {
          staticClass: "sub"
        })];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "备案总价",
      "width": "120px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c('p', [_vm._v(_vm._s(_vm._f("toThousands")(row.houseRecordPrice)))]), _c('p', {
          staticClass: "sub"
        })];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "总溢价",
      "width": "120px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c('p', [_vm._v(_vm._s(_vm._f("toThousands")(row.overflowTotalAmount)))]), _c('p', {
          staticClass: "sub"
        })];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "渠道成本",
      "width": "120px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_c('p', [_vm._v(_vm._s(_vm._f("toThousands")(row.channelCostAmount || 0)))]), _c('p', {
          staticClass: "sub"
        })];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "毛利润",
      "width": "120px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_c('p', [_vm._v(_vm._s(_vm._f("toThousands")(row.profitAmount)))]), _c('p', {
          staticClass: "sub"
        })];
      }
    }])
  })], 1), _vm.sumInfo.totalAmount ? _c('div', {
    staticClass: "table-count"
  }, [_c('strong', {
    staticClass: "cell"
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.sumInfo.totalAmount)))]), _c('strong', {
    staticClass: "cell"
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.sumInfo.houseRecordPrice)))]), _c('strong', {
    staticClass: "cell"
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.sumInfo.overflowTotalAmount)))]), _c('strong', {
    staticClass: "cell"
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.sumInfo.channelCostAmount)))]), _c('strong', {
    staticClass: "cell"
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.sumInfo.profitAmount)))])]) : _vm._e(), _c('div', {
    staticClass: "footer-bar mt10"
  }, [_c('div'), _c('f-pagination', {
    attrs: {
      "page-num": _vm.queryData.pageNum,
      "page-size": _vm.queryData.pageSize,
      "total": _vm.total,
      "fixed": false
    },
    on: {
      "update:pageNum": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:page-num": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:pageSize": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "update:page-size": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "change": _vm.getProfitCalcList
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }