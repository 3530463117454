<template>
  <div>
    <el-radio-group
      v-model="queryData.paymentStatus"
      size="medium"
      @change="search"
    >
      <el-radio-button label="1"
        >待回款({{ statusCount.unConfirm }})</el-radio-button
      >
      <el-radio-button label="2"
        >已回款({{ statusCount.confirm }})</el-radio-button
      >
    </el-radio-group>
    <!--filter-box-->
    <div class="filter-box mt15">
      <div class="filter">
        <el-input
          placeholder="订单/房源编号"
          v-model="queryData.keywords"
          @keyup.enter.native="search"
          style="width: 240px;"
          class="mr10 f-search-bar"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
        <el-select
          v-model="queryData.customerAuthId"
          placeholder="置业顾问"
          style="width: 135px;"
          class="mr10"
          clearable
          @change="search"
        >
          <el-option
            :value="item.id"
            :label="item.name"
            v-for="item in customerAuthList"
            :key="item.id"
          ></el-option>
        </el-select>
        <el-select
          v-model="queryData.payItemId"
          placeholder="款项类型"
          style="width: 135px;"
          class="mr10"
          clearable
          @change="search"
        >
          <el-option
            :value="item.id"
            :label="item.name"
            v-for="item of payCategoryList"
            :key="item.id"
            >{{ item.name }}</el-option
          >
        </el-select>
        <el-select
          v-model="queryData.payeeId"
          placeholder="收款方"
          style="width: 135px;"
          class="mr10"
          clearable
          filterable
          @change="handlePayeeChange"
        >
          <el-option
            :value="item.id"
            :label="item.name"
            v-for="item of payeeList"
            :key="item.id"
            >{{ item.name }}</el-option
          >
        </el-select>
        <el-date-picker
          v-model="date"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          style="width: 240px;"
          clearable
          filterable
          @change="dateChange"
        >
        </el-date-picker>
      </div>
      <div class="actions-btn">
        <el-button icon="iconfont icon-refresh" @click="getList"></el-button>
      </div>
    </div>
    <!--filter-box end-->
    <el-table
      border
      :data="list"
      class="mt15"
      :row-class-name="tableRowClassName"
      @sort-change="sortChange"
    >
      <el-table-column prop="orderNumber" label="订单编号">
        <template v-slot="{ row }">
          <el-link type="primary" @click="toOrderDetail(row)">{{
            row.orderNumber
          }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="customerAuthName" label="置业顾问">
      </el-table-column>
      <el-table-column prop="houseNumber" label="房源编号"> </el-table-column>
      <el-table-column prop="type" label="类型"></el-table-column>
      <el-table-column prop="period" label="期数"></el-table-column>
      <el-table-column prop="payeeName" label="收方"></el-table-column>
      <template v-if="queryData.paymentStatus == 1">
        <el-table-column
          prop="unPayedAmount"
          label="待回款金额"
          sortable="custom"
        >
          <template v-slot="{ row }">
            <span class="red">¥{{ row.unPayedAmount | toThousands }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="payedAmount" label="已收金额" sortable="custom">
          <template v-slot="{ row }">
            <span class="orange" v-if="row.payedAmount">
              ¥{{ row.payedAmount | toThousands }}
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </template>
      <template v-else>
        <el-table-column
          prop="payedAmount"
          label="已回款金额"
          sortable="custom"
        >
          <template v-slot="{ row }">
            <span class="orange" v-if="row.payedAmount">
              ¥{{ row.payedAmount | toThousands }}
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="unConfirmAmount"
          label="待确认金额"
          sortable="custom"
        >
          <template v-slot="{ row }">
            <span class="red" v-if="row.unConfirmAmount"
              >¥{{ row.unConfirmAmount | toThousands }}</span
            >
            <span v-else>-</span>
          </template>
        </el-table-column>
      </template>
      <el-table-column
        sortable="custom"
        :prop="queryData.paymentStatus == 1 ? 'orderDate' : 'payDate'"
        :label="queryData.paymentStatus == 1 ? '计划回款时间' : '收款日期'"
      ></el-table-column>
    </el-table>
    <div class="table-count" v-if="queryData.paymentStatus == 1">
      合计待回款<strong class="red"
        >¥{{ countInfo.unCollectionAmount | toThousands }}</strong
      >
    </div>
    <div class="table-count" v-else>
      <span>
        合计已回款<strong class="orange"
          >¥{{ countInfo.totalAmount | toThousands }}</strong
        >
      </span>
      <span v-if="countInfo.unConfirmAmount">
        其中，待确认<strong class="red"
          >¥{{ countInfo.unConfirmAmount | toThousands }}</strong
        >
      </span>
    </div>
    <div class="footer-bar mt10">
      <div class="action"></div>
      <f-pagination
        :page-num.sync="queryData.pageNum"
        :page-size.sync="queryData.pageSize"
        :total="total"
        :fixed="false"
        @change="getList"
      ></f-pagination>
    </div>
  </div>
</template>

<script>
import {
  orderPlanList,
  orderPlanListCount,
  getOrderConditionCustomerauth,
  paymentPlanTopCount,
  getPayeeList
} from "@/api/signedOrder";
export default {
  props: {
    estateId: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      orderStateMaps: {
        // 0, "待确认" 1, "已确认", 2, "转入房款"
        0: {
          label: "待确认",
          color: "red"
        },
        1: {
          label: "已确认",
          color: "green"
        },
        2: {
          label: "转入房款",
          color: ""
        }
      },
      date: "",
      queryData: {
        estateId: 6375,
        paymentStatus: "1", // 1未回款, 2已回款
        keywords: "", // 订单/房源编号
        customerAuthId: null, //职业顾问Id ,
        payeeType: null, //收款方: 0客户, 1公司, 2开发商
        payItemId: null, // 款项类别
        payeeId: null, //收款方
        type: null, //1定金, 2房款
        start: null,
        end: null,
        pageNum: 1,
        pageSize: 30,
        sortFiled: null // 1: 待回款金额大到小, 2: 待回款金额小到大, 3: 已收金额大到小, 4: 已收金额小到大, 5: 计划回款时间正, 6: 计划回款时间反, 7: 收款日期正, 8: 收款日期反 9: 待确认金额大到小, 10: 待确认金额小到大
      },
      list: [],
      total: 0,
      countInfo: {
        totalAmount: null,
        unConfirmAmount: null
      }, // 统计信息
      customerAuthList: [], // 置业顾问
      // tab统计数
      statusCount: {
        unConfirm: 0,
        confirm: 0
      },
      payCategoryList: [], //款项类别
      payeeList: [] //收款方
    };
  },
  watch: {
    estateId: {
      immediate: true,
      handler(id) {
        // 楼盘id变化时，重新获取数据
        this.queryData.estateId = id;
        this.getList();
        this.customerAuth();
        this.getPayeeList(2);
        this.getPayeeList(3);
      }
    }
  },
  methods: {
    // 获取收款方或者首款款项
    async getPayeeList(bizType) {
      const res = await getPayeeList({
        bizType, // 2为收款公司 3为收款项 ,
        estateId: this.estateId
      });
      if (res) {
        if (bizType === 3) {
          this.payCategoryList = res;
        } else {
          this.payeeList = [
            ...res,
            { name: "甲方", isFirstParty: true, id: -1 }
          ];
        }
      }
    },
    // 进入订单详情
    toOrderDetail({ orderId }) {
      // 打开新浏览器tab
      this.$openWindow(`/distribution/appManage/signedOrder/${orderId}`);
    },
    search() {
      this.queryData.pageNum = 1;
      this.getList();
    },
    getList() {
      this.orderPlanList();
      this.orderPlanListCount();
      this.paymentPlanTopCount();
    },
    // 选择收款方
    handlePayeeChange(id) {
      this.queryData.payeeType = id === -1 ? 2 : 1; // 甲方
      this.search();
    },
    handleQueryData() {
      const { payeeId } = this.queryData;
      return {
        ...this.queryData,
        payeeId: payeeId === -1 ? null : payeeId // 选择的甲方 请求参数的时候清空前端自定义的-1id
      };
    },
    async orderPlanList() {
      // 回款计划
      const res = await orderPlanList(this.handleQueryData());
      if (res) {
        this.list = res.list;
        this.total = res.total;
      }
    },
    async orderPlanListCount() {
      // 回款计划统计
      const res = await orderPlanListCount(this.handleQueryData());
      if (res) {
        this.countInfo = JSON.stringify(res) == "{}" ? null : res;
      }
    },
    async paymentPlanTopCount() {
      const res = await paymentPlanTopCount(this.handleQueryData());
      if (res) {
        this.statusCount = res;
      }
    },
    async customerAuth() {
      // 置业顾问
      const res = await getOrderConditionCustomerauth({
        estateId: this.queryData.estateId,
        orderType: 2
      });
      if (res) {
        this.customerAuthList = res;
      }
    },
    sortChange({ prop, order }) {
      // 排序
      let ascending = {
        unPayedAmount: 2,
        payedAmount: 4,
        orderDate: 6,
        unConfirmAmount: 10,
        payDate: 8
      };
      let descending = {
        unPayedAmount: 1,
        payedAmount: 3,
        orderDate: 5,
        unConfirmAmount: 9,
        payDate: 7
      };
      /*  sortFiled 1: 待回款金额大到小, 2: 待回款金额小到大, 3: 已收金额大到小, 4: 已收金额小到大, 5: 计划回款时间正, 6: 计划回款时间反, 7: 收款日期正, 8: 收款日期反
      9: 待确认金额大到小, 10: 待确认金额小到大 */
      if (order == "ascending") {
        // 升
        this.queryData.sortFiled = ascending[prop];
      } else if (order == "descending") {
        // 降
        this.queryData.sortFiled = descending[prop];
      } else {
        this.queryData.sortFiled = null;
      }
      this.orderPlanList();
    },
    dateChange(val) {
      // 日期
      let [start, end] = val || [];
      this.queryData.start = start;
      this.queryData.end = end;
      this.search();
    },
    tableRowClassName({ row }) {
      // 超期未回款, 套红显示
      if (this.queryData.paymentStatus == 1 && row.overdueUnPayment) {
        return "red";
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../_styles/house.scss";
.date-box {
  @include flex-ycb();
  ::v-deep .el-select {
    width: 105px;
    .el-input__inner {
      border-radius: 4px 0 0 4px;
    }
  }
  ::v-deep .el-date-editor {
    margin-left: -1px;
    width: 230px;
    border-radius: 0 4px 4px 0;
  }
}
.orange {
  color: $orange;
}
</style>
