<template>
  <div class="financial">
    <div class="filter-box1">
      <el-radio-group
        v-model="queryData.orderState"
        size="medium"
        @change="search('orderState')"
      >
        <el-radio-button :label="0"
          >未确认({{ statusCount.unConfirm }})</el-radio-button
        >
        <el-radio-button :label="1"
          >已确认({{ statusCount.confirm }})</el-radio-button
        >
        <el-radio-button label="refund"
          >退款记录({{ statusCount.refund }})</el-radio-button
        >
        <el-radio-button label="summary">汇总明细表</el-radio-button>
      </el-radio-group>
      <el-button
        type="primary"
        size="small"
        class="btn-create"
        @click="createRefund"
        >创建退款</el-button
      >
    </div>
    <!--filter-box-->
    <div class="filter-box mt15">
      <div class="filter">
        <el-input
          :placeholder="keywordsPlaceholder"
          v-model="queryData.keywords"
          @keyup.enter.native="search"
          style="width: 300px;"
          class="mr10 f-search-bar"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
        <el-select
          v-model="queryData.batch"
          placeholder="批次"
          clearable
          class="mr10"
          @change="search"
        >
          <el-option
            :value="item"
            :label="item"
            v-for="(item, index) in batchList"
            :key="index"
            @change="search"
          ></el-option>
        </el-select>
        <template v-if="queryData.orderState == 'summary'">
          <el-select
            v-model="status"
            placeholder="状态"
            style="width: 135px;"
            class="mr10"
            clearable
            @change="search"
          >
            <el-option
              v-for="item in statusTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
        <template v-else>
          <el-cascader
            placeholder="支付方式"
            :options="payTypeList"
            :props="{
              checkStrictly: true,
              label: 'name'
            }"
            v-model="queryData.payType"
            clearable
            class="mr10"
            style="width:130px"
            @change="handlePayType"
          ></el-cascader>
          <el-select
            v-model="queryData.payItemId"
            placeholder="款项类型"
            style="width: 135px;"
            class="mr10"
            clearable
            filterable
            @change="search"
          >
            <el-option
              :value="item.id"
              :label="item.name"
              v-for="item of payCategoryList"
              :key="item.id"
              >{{ item.name }}</el-option
            >
          </el-select>
          <el-select
            v-model="queryData.payeeId"
            :placeholder="
              queryData.orderState == 0
                ? '收款方'
                : queryData.orderState == 1
                ? '收/退款方'
                : '退款方'
            "
            style="width: 135px;"
            class="mr10"
            clearable
            filterable
            @change="handlePayeeChange"
          >
            <el-option
              :value="item.id"
              :label="item.name"
              v-for="item of payeeList"
              :key="item.id"
              >{{ item.name }}</el-option
            >
          </el-select>
          <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width: 240px;"
            @change="dateChange"
          >
          </el-date-picker>
        </template>
      </div>
      <div class="actions-btn">
        <el-button icon="iconfont icon-refresh" @click="getList"></el-button>
      </div>
    </div>
    <!--filter-box end-->
    <template v-if="queryData.orderState == 'summary'">
      <el-table
        :data="summaryList"
        @sort-change="sortSummary"
        :row-class-name="tableRowSummaryClassName"
        :span-method="objectSpanMethod"
        class="order-merge-table mt15"
        key="summaryList"
      >
        <el-table-column
          prop="orderCode "
          label="订单编号"
          width="150"
          align="center"
        >
          <template v-slot="{ row }">
            <span v-if="row.summary">合计</span>
            <span v-else>
              <el-link type="primary" @click="toOrderDetail(row.id)">
                {{ row.orderCode }}
              </el-link>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="houseNumber" label="房源编号" align="center">
          <template v-slot="{ row }">
            <el-link type="primary" @click="toOrderDetail(row.id)">
              {{ row.houseNumber }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column prop="customerName" label="客户姓名" align="center">
        </el-table-column>
        <el-table-column
          prop="signAmount"
          label="应收金额"
          align="center"
          width="120"
          sortable="custom"
          :sort-orders="['ascending', 'descending']"
        >
          <template v-slot="{ row }">
            ￥{{ row.signAmount | toThousands }}
          </template>
        </el-table-column>
        <el-table-column
          prop="payedAmount"
          label="收退款信息"
          align="center"
          width="100"
        >
          <el-table-column
            label="回款金额"
            prop="payedAmount"
            header-align="center"
            class-name="sub-title"
            width="120"
          >
            <template v-slot="{ row }">
              ￥{{ row.payedAmount | toThousands }}
            </template>
          </el-table-column>
          <el-table-column
            label="退款金额"
            prop="refundAmount"
            header-align="center"
            class-name="sub-title"
            width="120"
          >
            <template v-slot="{ row }">
              <span
                :class="{ red: row.refundAmount > 0 }"
                v-if="row.refundAmount > 0"
              >
                -￥{{ row.refundAmount | toThousands }}
              </span>
              <span v-else>￥0</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="realPayedAmount"
          label="实际回款"
          align="center"
          width="120"
          sortable="custom"
          :sort-orders="['ascending', 'descending']"
        >
          <template v-slot="{ row }">
            <span class="orange">
              ￥{{ row.realPayedAmount | toThousands }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="channelCommission"
          label="渠道结佣"
          align="center"
        >
          <el-table-column
            label="应结佣金"
            prop="channelCommissionAmount"
            header-align="center"
            class-name="sub-title"
            width="130"
          >
            <template v-slot="{ row }">
              ￥{{ row.channelCommissionAmount | toThousands }}
            </template>
          </el-table-column>
          <el-table-column
            label="已结佣金"
            prop="channelCommissionedAmount"
            header-align="center"
            class-name="sub-title"
            width="120"
          >
            <template v-slot="{ row }">
              ￥{{ row.channelCommissionedAmount | toThousands }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="channelCommissionSurplusAmount"
          label="未结佣金"
          align="center"
          width="120"
        >
          <template v-slot="{ row }">
            <span class="red">
              ￥{{ row.channelCommissionSurplusAmount | toThousands }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="订单状态"
          align="center"
          width="100"
        >
          <template v-slot="{ row }">
            <span
              :style="{ color: statusMaps[row.status].color }"
              v-if="row.status"
              >{{ statusMaps[row.status].label }}</span
            >
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <template v-else>
      <el-table
        key="list"
        :data="list"
        @selection-change="selectChange"
        @row-click="rowClick"
        @sort-change="sortChange"
        :row-class-name="tableRowClassName"
        class="order-merge-table mt15"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
          :key="Math.random()"
          v-if="queryData.orderState == 0"
        >
        </el-table-column>
        <el-table-column
          width="55"
          align="center"
          label="类型"
          prop="paymentTypeEnum"
          v-if="queryData.orderState == 1"
        >
          <template v-slot="{ row }">
            {{ row.paymentTypeEnum == 0 ? "收款" : "退款" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="receiptNo"
          :label="
            queryData.orderState == 0
              ? '收款单号'
              : queryData.orderState == 1
              ? '收/退单号'
              : '退款单号'
          "
          width="150"
          align="center"
        >
          <template v-slot="{ row }">
            <el-button type="text" @click="showFinancialDetail(row)">{{
              row.receiptNo
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="houseNumber"
          label="房源编号"
          align="center"
          width="100"
        >
          <template v-slot="{ row }">
            {{ row.houseNumber }}
          </template>
        </el-table-column>
        <el-table-column
          prop="customerName"
          label="客户姓名"
          align="center"
          width="100"
        >
        </el-table-column>
        <el-table-column
          :label="
            queryData.orderState == 0
              ? '收款信息'
              : queryData.orderState == 1
              ? '收/退款信息'
              : '退款信息'
          "
          align="center"
        >
          <el-table-column
            prop="payItemName"
            label="类型"
            class-name="sub-title"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="payeeName"
            :label="
              queryData.orderState == 0
                ? '收款方'
                : queryData.orderState == 1
                ? '收/退方'
                : '退款方'
            "
            class-name="sub-title"
            align="center"
            width="80"
          >
            <template v-slot="{ row }">
              {{
                queryData.orderState === 0 ? row.payeeName : row.payerTypeStr
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="paymentTypeName"
            label="支付方式"
            align="center"
            class-name="sub-title"
          >
            <template v-slot="{ row }">
              <span v-if="row.depositGroup">定金组合</span>
              <span v-else-if="row.orderState == 2">定金转入</span>
              <span v-else-if="row.paymentType === 1">
                POS机<span v-if="row.payWayInfo"
                  >/{{ row.payWayInfo.name }}</span
                >
              </span>
              <span v-else>{{ row.paymentTypeName || "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="paymentTypeName"
            :label="
              queryData.orderState == 0
                ? '收款账户'
                : queryData.orderState == 1
                ? '收/退账户'
                : '退款账户'
            "
            align="center"
            class-name="sub-title"
          >
            <template v-slot="{ row }">
              <template v-if="row.payWayInfo">
                <span v-if="row.payWayInfo.payMethod == 0">
                  {{ row.payWayInfo.name }}
                </span>
                <span v-else-if="row.payWayInfo.payMethod == 1">
                  终端号{{ row.payWayInfo.clientNo }}
                </span>
                <span v-else>-</span>
              </template>
              <template v-else>-</template>
            </template>
          </el-table-column>
          <el-table-column
            prop="transactionNo"
            label="交易单号"
            align="center"
            class-name="sub-title"
          >
            <template v-slot="{ row }">
              <span>{{ row.transactionNo || "-" }}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="totalAmount"
          :label="
            queryData.orderState == 0
              ? '收款金额'
              : queryData.orderState == 1
              ? '收/退金额'
              : '退款金额'
          "
          sortable="custom"
          :sort-orders="['ascending', 'descending']"
          align="center"
        >
          <template v-slot="{ row }">
            <span
              :class="row.paymentTypeEnum == 1 ? 'red' : 'orange'"
              :style="{
                textDecoration:
                  row.type == '定金' && row.transfered ? 'line-through' : 'none'
              }"
            >
              ¥{{ row.totalAmount | toThousands }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="paymentDate"
          :label="
            queryData.orderState == 0
              ? '收款日期'
              : queryData.orderState == 1
              ? '收/退日期'
              : '退款日期'
          "
          sortable="custom"
          :sort-orders="['ascending', 'descending']"
          align="center"
        ></el-table-column>
        <el-table-column prop="remark" align="center" label="备注">
          <template v-slot="{ row }">
            {{ row.remark || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="action" label="操作" align="center">
          <template v-slot="scope">
            <!--“定金”，“转入房款”，显示“转为房款”，隐藏所有操作按钮-->
            <span v-if="scope.row.type == '定金' && scope.row.transfered">
              转为{{ scope.row.transferTo === 1 ? "定金" : "房款" }}
            </span>
            <template v-else>
              <!--“未确认”，显示【确认】-->
              <el-link
                type="primary"
                class="mr10"
                @click="sure([scope.row.id])"
                v-if="queryData.orderState == 0"
              >
                确认
              </el-link>
              <!--“未确认”，隐藏【编辑】-->
              <el-link
                v-if="queryData.orderState !== 0"
                type="primary"
                class="mr10"
                @click="showEditReceipt(scope.row)"
                >编辑</el-link
              >
              <!--“未确认”，非“定金转入”，隐藏【删除】-->
              <el-link
                v-if="
                  !(queryData.orderState == 0 && scope.row.paymentType !== 5)
                "
                type="danger"
                @click="
                  scope.row.paymentTypeEnum == 0
                    ? deleteReceipt([scope.row.id])
                    : deleteRefund(scope.row.id)
                "
              >
                删除
              </el-link>
              <!--“未确认”，非“定金转入”，显示【更多】-->
              <el-dropdown
                v-if="queryData.orderState == 0 && scope.row.paymentType !== 5"
                placement="top-start"
                @command="moreAction($event, scope.row)"
                :ref="'drop-' + scope.$index"
              >
                <el-link type="primary">更多</el-link>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="showEditReceipt">
                    编辑
                  </el-dropdown-item>
                  <el-dropdown-item command="deleteReceipt">
                    删除
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <div class="table-count">
        <div>
          <el-checkbox
            v-model="isCheckAll"
            :indeterminate="indeterminate"
            v-if="queryData.orderState == 0"
            @change="checkAll"
            >全选</el-checkbox
          >
        </div>
        <div class="count">
          <template v-if="queryData.orderState == 0">
            合计待确认收款<span
              >¥<strong>{{ countInfo | toThousands }}</strong></span
            >
          </template>
          <template v-else-if="queryData.orderState == 1">
            合计已确认收款<span class="orange"
              >¥<strong>{{ countInfo | toThousands }}</strong></span
            >
          </template>
          <template v-else>
            合计退款金额<span class="red"
              >¥<strong>{{ countInfo | toThousands }}</strong></span
            >
          </template>
        </div>
      </div>
    </template>
    <div class="footer-bar mt10">
      <div class="action">
        <template v-if="queryData.orderState == 0 && checkList.length">
          <el-button type="info" class="mr10" @click="batchComfirm"
            >批量确认</el-button
          >
          <el-button type="info" @click="batchDelete">批量删除</el-button>
        </template>
      </div>
      <f-pagination
        :page-num.sync="queryData.pageNum"
        :page-size.sync="queryData.pageSize"
        :total="total"
        :fixed="false"
        @change="getList"
      ></f-pagination>
    </div>
    <!-- 编辑收款弹窗 -->
    <edit-company-receipt
      :visible.sync="editCompanyReceiptVisible"
      :order-id="curRow.orderId"
      :receipt-id="curRow.id"
      :success="handleEditSuccess"
    ></edit-company-receipt>
    <!-- 编辑退款弹窗 -->
    <edit-company-refund
      :visible.sync="editCompanyRefundVisible"
      :estateId="estateId"
      :order-id="curRow.orderId"
      :refund-type="curRow.type"
      :id="curRow.id"
      :success="handleEditSuccess"
    ></edit-company-refund>
    <financial-detail
      ref="financialDetail"
      :visible.sync="financialDetailVisible"
      :estateId="estateId"
      :id="curRow.id"
      :orderId="curRow.orderId"
      @sure="sure([curRow.id])"
      @del="
        curRow.paymentTypeEnum === 0
          ? deleteReceipt([curRow.id])
          : deleteRefund(curRow.id)
      "
      @edit="showEditReceipt(curRow)"
    ></financial-detail>
  </div>
</template>

<script>
import {
  financialList,
  financialListCount,
  financialSummary,
  financialSummarySum,
  financialDel,
  financialConfirm,
  paymentFinancialTopCount,
  paymentType,
  deleteSettlementRefund,
  getPayeeList
} from "@/api/signedOrder";

import EditCompanyReceipt from "../../../detail/_components/companyAccount/editReceipt";
import EditCompanyRefund from "../../../detail/_components/companyAccount/editRefund";
import FinancialDetail from "./_components/financialDetail";
export default {
  components: {
    EditCompanyReceipt,
    EditCompanyRefund,
    FinancialDetail
  },
  props: {
    estateId: {
      type: [String, Number],
      default: null
    },
    batchList: {
      // 批次列表
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      date: "",
      queryData: {
        orderState: 0, // 0：待确认 1：已确认 2：退款记录 ,
        estateId: null,
        keywords: "", // 收款单号/订单编号/交易单号
        batch: "", // 批次
        type: null, //1定金, 2房款
        payeeType: null, //收款方:  1公司, 2甲方
        payItemId: null, // 款项类别
        payeeId: null, //收款方
        start: "",
        end: "",
        payMethod: null, // 支付方式
        name: null, // 品牌名/别名
        mchNo: null, // 卡号/终端号
        sortFiled: null, // 1: 收款金额大到小, 2: 收款金额小到大, 3: 收款日期正, 4: 收款日期反
        pageSize: 30,
        pageNum: 1,
        paymentType: 0 // 0-收款 1-退款
      },
      summarySort: {}, // 汇总明细表排序指标
      status: "", // 汇总明细状态筛选
      editCompanyReceiptVisible: false, //控制显示编辑收款弹窗
      editCompanyRefundVisible: false, // 控制显示编辑退款弹窗
      list: [],
      summaryList: [], // 汇总明细表
      summarySum: {}, // 汇总明细表统计
      total: 0,
      countInfo: null, // 统计信息
      // tab统计数
      statusCount: {
        unConfirm: 0,
        confirm: 0
      },
      payType: null, // 收款方式
      payTypeList: [],
      isCheckAll: false, // 自定义全选
      indeterminate: false, // 自定义半选状态
      checkList: [], // 选中的行
      financialDetailVisible: false, // 控制详情抽屉显示
      curRow: {},
      statusTypes: [
        {
          label: "待签约",
          value: 1
        },
        {
          label: "回款中",
          value: 5
        },
        {
          label: "已回款",
          value: 10
        },
        {
          label: "结佣中",
          value: 15
        },
        {
          label: "已结佣",
          value: 20
        },
        {
          label: "退款中",
          value: 25
        },
        {
          label: "已退款",
          value: 100
        }
      ],
      statusMaps: {
        1: {
          color: "#333",
          label: "待签约"
        },
        5: {
          color: "#f59a23",
          label: "回款中"
        },
        10: {
          color: "#00b2ff",
          label: "已回款"
        },
        15: {
          color: "#f59a23",
          label: "结佣中"
        },
        20: {
          color: "#70b603",
          label: "已结佣"
        },
        25: {
          color: "#d9001b",
          label: "退款中"
        },
        100: {
          color: "#7f7f7f",
          label: "已退款"
        }
      },
      payCategoryList: [], //款项类别
      payeeList: [] //收款方
    };
  },
  watch: {
    estateId: {
      immediate: true,
      handler(id) {
        if (id) {
          // 楼盘id变化时，重新获取数据
          this.queryData.estateId = id;
          // 切换楼盘里，清空上次查询条件
          this.queryData.batch = "";
          this.queryData.payType = null;
          this.getList();
          this.getPaymentType();
          this.getPayeeList(2);
          this.getPayeeList(3);
        }
      }
    },
    "queryData.orderState": {
      immediate: true,
      handler(val) {
        if (val == 0) {
          // 待确认，收款
          this.queryData.paymentType = 0;
        } else if (val == "refund") {
          // 退款记录
          this.queryData.paymentType = 1;
        } else {
          this.queryData.paymentType = null;
        }
      }
    }
  },
  computed: {
    keywordsPlaceholder() {
      let maps = {
        0: "房源编号、收款单号",
        1: "房源编号、收/退款单号",
        refund: "房源编号、退款单号",
        summary: "房源编号、订单编号"
      };
      return maps[this.queryData.orderState];
    }
  },
  methods: {
    // 获取收款方或者首款款项
    async getPayeeList(bizType) {
      const res = await getPayeeList({
        bizType, // 2为收款公司 3为收款项 ,
        estateId: this.estateId
      });
      if (res) {
        if (bizType === 3) {
          this.payCategoryList = res;
        } else {
          this.payeeList = [
            ...res,
            { name: "甲方", isFirstParty: true, id: -1 }
          ];
        }
      }
    },
    tableRowClassName({ row }) {
      if (row.type === "定金" && row.transfered) {
        return "gray";
      }
    },
    tableRowSummaryClassName({ row, rowIndex }) {
      if (rowIndex == this.summaryList.length - 1) {
        return "summary";
      }
    },
    // 进入订单详情
    toOrderDetail(id) {
      // 打开新浏览器tab
      window.open(
        `${location.origin}/#/distribution/appManage/signedOrder/${id}`
      );
    },
    async getPaymentType() {
      // 获取收款方式
      const res = await paymentType(this.estateId);
      if (res) {
        this.payTypeList = res;
      }
    },
    handlePayType(arr) {
      // 设置收款方式
      this.queryData.payMethod = arr[0] || null;
      this.queryData.name = arr[1] || null;
      this.queryData.mchNo = arr[2] || null;
      this.search();
    },
    search() {
      this.queryData.pageNum = 1;
      this.getList();
    },
    // 选择收款方
    handlePayeeChange(id) {
      this.queryData.payeeType = id === -1 ? 2 : 1; // 甲方
      this.search();
    },
    getList() {
      if (this.queryData.orderState == "summary") {
        this.financialSummary();
        this.paymentFinancialTopCount();
      } else {
        this.financialList();
        this.financialListCount();
        this.paymentFinancialTopCount();
      }
    },
    handleQueryData() {
      const { payeeId } = this.queryData;
      return {
        ...this.queryData,
        payeeId: payeeId === -1 ? null : payeeId // 选择的甲方 请求参数的时候清空前端自定义的-1id
      };
    },
    async financialList() {
      // 回款计划
      let params = this.handleQueryData();
      if (this.queryData.orderState == "refund") {
        params.orderState = null;
      }
      const res = await financialList(params);
      if (res) {
        this.list = res.list;
        this.total = res.total;
      }
    },
    async financialListCount() {
      // 回款计划统计
      let params = this.handleQueryData();
      if (this.queryData.orderState == "refund") {
        params.orderState = null;
      }
      const res = await financialListCount(params);
      if (res) {
        this.countInfo = JSON.stringify(res) == "{}" ? null : res;
      }
    },
    async paymentFinancialTopCount() {
      let params = this.handleQueryData();
      params.paymentType = null;
      if (
        this.queryData.orderState == "refund" ||
        this.queryData.orderState == "summary"
      ) {
        params.orderState = null;
      }
      const res = await paymentFinancialTopCount(params);
      if (res) {
        this.statusCount = res;
      }
    },
    // 汇总明细表
    async financialSummary() {
      let params = {
        estateId: this.queryData.estateId,
        keyword: this.queryData.keywords,
        batch: this.queryData.batch, // 批次
        pageNum: this.queryData.pageNum,
        pageSize: this.queryData.pageSize,
        status: this.status,
        ...this.summarySort
      };
      Promise.all([financialSummary(params), financialSummarySum(params)]).then(
        res => {
          if (res[0] && res[1]) {
            if (res[0].list && res[1]) {
              this.summaryList = [...res[0].list, { ...res[1], summary: true }];
              this.total = res[0].total;
            } else {
              this.summaryList = [];
              this.total = 0;
            }
          }
        }
      );
    },
    sortChange({ prop, order }) {
      //sortFiled 1: 收款金额大到小, 2: 收款金额小到大, 3: 收款日期正, 4: 收款日期反
      let ascending = {
        totalAmount: 2,
        paymentDate: 4
      };
      let descending = {
        totalAmount: 1,
        paymentDate: 3
      };
      if (order == "ascending") {
        // 升
        this.queryData.sortFiled = ascending[prop];
      } else if (order == "descending") {
        // 降
        this.queryData.sortFiled = descending[prop];
      } else {
        this.queryData.sortFiled = null;
      }
      this.financialList();
    },
    sortSummary({ prop, order }) {
      // sort 1 应收金额 2  实际回款
      // sortType 1 desc 0 asc
      const sortMap = {
        signAmount: 1,
        realPayedAmount: 2
      };
      this.summarySort = {
        sort: sortMap[prop],
        sortType: order == "descending" ? 1 : 0
      };
      this.financialSummary();
    },
    async sure(ids) {
      // 确认
      this.$confirm(`请确认是否完成财务对账，执行确认收款？`, "确认收款", {
        type: "warning"
      })
        .then(async () => {
          const res = await financialConfirm({ ids });
          if (res) {
            this.$showSuccess("操作成功");
            this.getList();
            if (this.financialDetailVisible && this.curRow.id) {
              this.$refs.financialDetail.getFinancialDetail(this.curRow.id); // 刷新收款单详情
            }
          }
        })
        .catch(() => {});
    },
    // 删除收款
    async deleteReceipt(ids) {
      this.$confirm(`删除后无法恢复收款记录，确定要删除？`, "删除收款", {
        type: "warning",
        confirmButtonClass: "el-button--danger"
      })
        .then(async () => {
          const res = await financialDel({ ids });
          if (res) {
            this.$showSuccess("操作成功");
            this.getList();
            this.financialDetailVisible = false; // 删除成功后，关闭收款单详情drawer
          }
        })
        .catch(() => {});
    },
    // 删除退款
    async deleteRefund(id) {
      this.$confirm(`删除后无法恢复，确定要删除？`, "删除退款", {
        type: "warning",
        confirmButtonClass: "el-button--danger"
      })
        .then(async () => {
          const res = await deleteSettlementRefund({ id });
          if (res) {
            this.$showSuccess("操作成功");
            this.getList();
            this.financialDetailVisible = false; // 删除成功后，关闭收款单详情drawer
          }
        })
        .catch(() => {});
    },
    handleEditSuccess() {
      this.getList();
      if (this.financialDetailVisible && this.curRow.id) {
        this.$refs.financialDetail.getFinancialDetail(this.curRow.id); // 刷新收款单详情
      }
    },
    async batchComfirm() {
      // 批量确认
      let ids = this.checkList.map(item => item.id);
      this.sure(ids);
    },
    async batchDelete() {
      // 批量删除
      let ids = this.checkList.map(item => item.id);
      this.deleteReceipt(ids);
    },
    createRefund(row) {
      this.curRow = {};
      this.editCompanyRefundVisible = true;
    },
    async showEditReceipt(row) {
      // 显示编辑收款弹窗
      this.curRow = row;
      if (row.paymentTypeEnum == 0) {
        this.editCompanyReceiptVisible = true;
      } else {
        this.editCompanyRefundVisible = true;
      }
    },
    // 显示详情抽屉
    showFinancialDetail(row) {
      this.curRow = row;
      this.financialDetailVisible = true;
    },
    moreAction(type, row) {
      // 更多操作，编辑 删除
      if (type == "deleteReceipt") {
        this[type]([row.id]);
      } else {
        this[type](row);
      }
    },
    dateChange(val) {
      // 日期
      let [start, end] = val || [];
      this.queryData.start = start;
      this.queryData.end = end;
      this.search();
    },
    rowClick(row, column) {
      if (column.property !== "receiptNo" && column.property !== "action") {
        this.$refs.multipleTable.toggleRowSelection(row);
      }
    },
    checkAll(val) {
      // 自定义全选
      this.$refs.multipleTable.toggleAllSelection();
    },
    selectChange(selection) {
      // 选中行发生变化时
      let checkAbleCount = this.list.length; // 可选条数
      let checkedCount = selection.length; // 选中的条数

      // 设置自定义全选的选中状态
      if (checkedCount == checkAbleCount) {
        this.isCheckAll = true; // 全选
        this.indeterminate = false; // 半选
      } else {
        this.isCheckAll = false; // 全选
        this.indeterminate = Boolean(checkedCount); // 半选
      }

      // 获取选中的行
      this.checkList = selection;
    },
    // 合并单元格
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (row.summary) {
        if (columnIndex === 0) {
          return [1, 4];
        } else if (columnIndex >= 1 && columnIndex <= 3) {
          return [0, 0];
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.filter-box1 {
  @include flex-ycb();
  .el-select {
    width: 125px;
  }
  .action {
    display: flex;
  }
}
.filter-box {
  display: flex;
  .filter {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
  }
}
.financial {
  ::v-deep {
    .el-table {
      tr.gray {
        * {
          color: #999 !important;
        }
      }
    }
  }
}
.date-box {
  @include flex-ycb();
  ::v-deep .el-select {
    width: 105px;
    .el-input__inner {
      border-radius: 4px 0 0 4px;
    }
  }
  ::v-deep .el-date-editor {
    margin-left: -1px;
    width: 230px;
    border-radius: 0 4px 4px 0;
  }
}
.orange {
  color: $orange;
}
::v-deep .el-link.el-link--danger {
  color: $red;
}
.table-count {
  @include flex-ycb;
  background-color: #f2f2f2;
  font-size: 14px;
  color: $color9;
  padding: 15px 27px;
  span + span {
    margin-left: 20px;
  }
  span {
    color: #333;
    font-size: 16px;
    margin-left: 8px;
    &.red {
      color: $red;
    }
    &.orange {
      color: $orange;
    }
  }
}
.footer-bar {
  @include flex-ycb();
  .el-button--info {
    color: $color3;
    background-color: #eee;
    border-color: #eee;
    &:active {
      background-color: #ddd;
    }
  }
}

::v-deep .el-table--border {
  th:first-child .cell,
  td:first-child .cell {
    padding: 0;
  }
}
::v-deep .el-table tr.summary {
  font-weight: bold;
}
</style>
