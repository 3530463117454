<template>
  <div class="channel-account">
    <!--filter-box-->
    <div class="filter-box mt15">
      <div class="filter">
        <el-date-picker
          v-model="date"
          type="monthrange"
          align="right"
          range-separator="至"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          value-format="yyyy-MM-dd"
          @change="dateChange"
        >
        </el-date-picker>
      </div>
    </div>
    <!--filter-box end-->
    <el-table
      :data="list"
      border
      class="mt15"
      ref="multipleTable"
      @sort-change="sortChange"
    >
      <el-table-column label="签约订单">
        <template v-slot="{ row }">
          <el-link type="primary" @click="toOrderDetail(row.id)">{{
            row.houseNumber
          }}</el-link>
          <p class="sub">{{ row.orderCode }}</p>
        </template>
      </el-table-column>
      <el-table-column label="渠道信息">
        <template v-slot="{ row }">
          <p>{{ row.mechanismName }}</p>
          <p class="sub">{{ row.agentUser }}</p>
        </template>
      </el-table-column>
      <el-table-column label="签约/结佣" prop="orderDateDesc" sortable="custom">
        <template v-slot="{ row }">
          <p>{{ row.orderDateDesc }}</p>
          <p class="sub">{{ row.commissionedDateDesc || "-" }}</p>
        </template>
      </el-table-column>
      <el-table-column label="成交总额" width="120px">
        <template v-slot="{ row }">
          <p>{{ row.totalAmount | toThousands }}</p>
          <p class="sub"></p>
        </template>
      </el-table-column>
      <el-table-column label="备案总价" width="120px">
        <template v-slot="{ row }">
          <p>{{ row.houseRecordPrice | toThousands }}</p>
          <p class="sub"></p>
        </template>
      </el-table-column>
      <el-table-column label="总溢价" width="120px">
        <template v-slot="{ row }">
          <p>{{ row.overflowTotalAmount | toThousands }}</p>
          <p class="sub"></p>
        </template>
      </el-table-column>
      <el-table-column label="渠道成本" width="120px">
        <template v-slot="{ row }">
          <p>{{ (row.channelCostAmount || 0) | toThousands }}</p>
          <p class="sub"></p>
        </template>
      </el-table-column>
      <el-table-column label="毛利润" width="120px">
        <template v-slot="{ row }">
          <p>{{ row.profitAmount | toThousands }}</p>
          <p class="sub"></p>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-count" v-if="sumInfo.totalAmount">
      <strong class="cell">{{ sumInfo.totalAmount | toThousands }}</strong>
      <strong class="cell">{{ sumInfo.houseRecordPrice | toThousands }}</strong>
      <strong class="cell">{{
        sumInfo.overflowTotalAmount | toThousands
      }}</strong>
      <strong class="cell">{{
        sumInfo.channelCostAmount | toThousands
      }}</strong>
      <strong class="cell">{{ sumInfo.profitAmount | toThousands }}</strong>
    </div>
    <div class="footer-bar mt10">
      <div></div>
      <f-pagination
        :page-num.sync="queryData.pageNum"
        :page-size.sync="queryData.pageSize"
        :total="total"
        :fixed="false"
        @change="getProfitCalcList"
      ></f-pagination>
    </div>
  </div>
</template>

<script>
import { getProfitCalcList, getProfitCalcCounts } from "@/api/signedOrder";
export default {
  props: ["estateId"],
  data() {
    return {
      date: "",
      queryData: {
        channelName: "", //渠道id ,
        end: "", // 结束日期 ,
        estateId: "", //楼盘id ,
        keywords: "", // 订单编号、房源编号 ,
        pageNum: 1, //,
        pageSize: 30, //,
        start: "", // 开始日期 ,
        status: 2 // 结佣状态(1: 预结佣, 2: 结佣中, 3: 已结佣)
      },
      // 统计
      sumInfo: {
        totalAmount: 0,
        houseRecordPrice: 0,
        channelCostAmount: 0,
        overflowTotalAmount: 0,
        profitAmount: 0
      },
      list: [],
      total: 0
    };
  },
  watch: {
    estateId: {
      immediate: true,
      handler(id) {
        // 楼盘id变化时，重新获取数据
        if (id) {
          this.queryData.estateId = id;
          this.getProfitCalcList();
          this.getProfitCalcCounts();
        }
      }
    }
  },
  methods: {
    // 进入订单详情
    toOrderDetail(id) {
      // 打开新浏览器tab
      this.$openWindow(`/distribution/appManage/signedOrder/${id}`);
    },
    search() {
      this.queryData.pageNum = 1;
      this.getData();
    },
    getData() {
      this.getProfitCalcList();
      this.getProfitCalcCounts();
    },
    // 获取渠道结佣列表
    async getProfitCalcList() {
      const res = await getProfitCalcList(this.queryData);
      if (res) {
        this.list = res.list;
        this.total = res.total;
      }
    },
    // 获取渠道结佣统计
    async getProfitCalcCounts() {
      const res = await getProfitCalcCounts(this.queryData);
      if (res) {
        this.sumInfo = res;
      }
    },
    dateChange(val) {
      // 日期
      const [startDate, endDate] = val || [];
      Object.assign(this.queryData, {
        startDate,
        endDate
      });
      this.search();
    },
    sortChange({ prop, order }) {
      //"排序: 0添加时间 1总溢价 2 认购日期 3 签约日期 4 回款日期 5 提交结佣日期 6 结佣日期 ,
      const sortValMap = {
        orderDateDesc: 3
      };
      const sortMap = {
        ascending: 0,
        descending: 1
      };
      this.queryData.sort = sortMap[order];
      this.queryData.sortType = sortValMap[prop];
      this.getProfitCalcList();
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../_styles/house.scss";
.filter {
  ::v-deep .el-date-editor {
    margin-left: -1px;
    width: 230px;
    border-radius: 0 4px 4px 0;
  }
}
.channel-account {
  ::v-deep {
    .el-table {
      .cell {
        p {
          font-size: 14px;
          color: #333;
        }
        .sub {
          padding-top: 2px;
          font-size: 13px;
          line-height: 20px;
          height: 20px;
          color: #999;
        }
      }
    }
  }
}
.table-count {
  @include flex-yc;
  justify-content: flex-end;
  padding-right: 0;
  strong {
    padding: 0 10px;
    width: 120px;
    display: block;
    color: #333;
    text-align: left;
    margin-left: 0;
  }
}
.orange {
  color: $orange !important;
}
</style>
