<template>
  <div>
    <div class="tips">
      [温馨提示]“顾问”，即认购置业顾问；“底”，即销售底总价；
      “溢”，即溢价=认购价格-控制收款额；“率”，即总溢价率或毛利率=总溢价÷认购价格；“收”，即已确认收到的定金；
    </div>
    <!--filter-box-->
    <div class="filter-box mt20">
      <div class="filter">
        <el-input
          placeholder="订单/房源编号、客户姓名/电话"
          v-model="queryData.keyword"
          @keyup.enter.native="search"
          style="width: 295px;"
          class="mr10 f-search-bar"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
        <el-select
          v-model="queryData.batch"
          placeholder="批次"
          clearable
          class="mr10"
          @change="search"
        >
          <el-option
            :value="item"
            :label="item"
            v-for="(item, index) in batchList"
            :key="index"
            @change="search"
          ></el-option>
        </el-select>
        <el-select
          v-model="queryData.channelName"
          placeholder="渠道"
          filterable
          clearable
          style="width: 135px;"
          class="mr10"
          @change="search"
        >
          <el-option
            :value="item.name"
            :label="item.name"
            v-for="(item, index) in channelList"
            :key="index"
          ></el-option>
        </el-select>
        <el-select
          v-model="queryData.customerAuthId"
          placeholder="置业顾问"
          style="width: 135px;"
          class="mr10"
          clearable
          @change="search"
        >
          <el-option
            :value="item.id"
            :label="item.name"
            v-for="(item, index) in customerAuthList"
            :key="index"
          ></el-option>
        </el-select>
        <div class="date-box">
          <el-select v-model="queryData.dateType" @change="search">
            <el-option
              v-for="item in dateTypes"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
          <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="dateChange"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="actions-btn">
        <el-button icon="iconfont icon-refresh" @click="getList"></el-button>
      </div>
    </div>
    <!--filter-box end-->
    <el-table
      :data="list"
      class="mt15"
      border
      :row-class-name="tableRowClassName"
      @sort-change="sortChange"
    >
      <el-table-column prop="orderCode" label="订单编号" width="140">
        <template v-slot="{ row }">
          <template v-if="row.summary">
            <div class="title">合计</div>
          </template>
          <template v-else>
            <div class="title">
              <el-link type="primary" @click="toOrderDetail(row.id)">{{
                row.orderCode
              }}</el-link>
            </div>
            <p>顾问 {{ row.customerAuthName }}</p>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="houseBuildingSurface" label="房源信息">
        <template v-slot="{ row }">
          <template v-if="row.summary">
            <div class="title">{{ row.houseBuildingSurface }}㎡</div>
          </template>
          <template v-else>
            <div class="title">{{ row.houseNumber }}</div>
            <p>{{ row.houseBuildingSurface }}㎡</p>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="houseSalesPrice" label="计划收款额">
        <template v-slot="{ row }">
          <div class="title">{{ row.houseSalesPrice | toThousands }}</div>
          <p>
            <span v-if="!row.summary">底</span>
            {{ row.houseSalesReservePrice | toThousands }}
          </p>
        </template>
      </el-table-column>
      <el-table-column prop="totalAmount" label="认购总额">
        <template v-slot="{ row }">
          <div class="title">{{ row.totalAmount | toThousands }}</div>
          <p>
            <span v-if="!row.summary">溢</span>
            <span :class="{ red: row.overflowAmount < 0 }">{{
              row.overflowAmount | toThousands
            }}</span>
          </p>
        </template>
      </el-table-column>
      <el-table-column
        prop="overflowTotalAmount"
        label="总溢价"
        sortable="custom"
      >
        <template v-slot="{ row }">
          <div class="title">
            {{ row.overflowTotalAmount | toThousands }}
          </div>
          <p>
            <span v-if="!row.summary">率</span>
            {{ $calc.accMulti(row.overflowTotalRate, 100) }}%
          </p>
        </template>
      </el-table-column>
      <el-table-column prop="depositAmount" label="定金回款">
        <template v-slot="{ row }">
          <div class="title">
            <span class="orange">{{ row.receivedAmount | toThousands }}</span>
          </div>
          <!--转出金额-->
          <p v-if="row.transferedAmount && !row.summary">
            <span>转{{ row.transferedAmount | toThousands }} </span>
          </p>
          <!--退款-->
          <p v-if="[25, 100].includes(row.status)">
            <span :class="{ red: !row.summary }" v-if="row.extendStatus !== 1">
              退{{ row.refundAmount | toThousands }}
            </span>
          </p>
          <!--欠款-->
          <p v-else-if="row.unReceiveAmount">
            欠{{ row.unReceiveAmount | toThousands }}
          </p>
          <p
            v-else-if="
              row.status != 5 &&
                !row.unReceiveAmount &&
                !row.refundAmount &&
                !row.transferedAmount &&
                !row.summary
            "
          >
            已回款
          </p>
        </template>
      </el-table-column>
      <el-table-column prop="customerName" label="认购客户">
        <template v-slot="{ row }">
          <div class="title">{{ row.customerName }}</div>
          <p>{{ row.customerPhone | formatPhone }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="channelName" label="渠道信息">
        <template v-slot="{ row }">
          <template v-if="!row.summary">
            <div class="title">{{ row.channelName || "自然到访" }}</div>
            <p>{{ row.agentUser }}</p>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="orderDateDesc" label="认购日期" sortable="custom">
        <template v-slot="{ row }">
          <template v-if="!row.summary">
            <div class="title">{{ row.orderDateDesc }}</div>
            <p>
              <span v-if="row.status == 1" :class="{ red: row.overdueDay < 0 }">
                计划签约{{ row.signDateDesc }}
              </span>
              <span class="red" v-if="row.status == 25">
                退款中
              </span>
              <span v-if="row.status == 100">
                已取消({{ row.extendStatus == 1 ? "他签" : "已退款" }})
              </span>
            </p>
          </template>
        </template>
      </el-table-column>
    </el-table>

    <div class="footer-bar mt10">
      <div class="action"></div>
      <f-pagination
        :page-num.sync="queryData.pageNum"
        :page-size.sync="queryData.pageSize"
        :total="total"
        :fixed="false"
        @change="getList"
      ></f-pagination>
    </div>
  </div>
</template>

<script>
import {
  getOrderConditionCustomerauth,
  getOrderConditionChannel,
  getOrderListSum,
  getOrderList
} from "@/api/signedOrder";
export default {
  props: {
    estateId: {
      type: [String, Number],
      default: null
    },
    batchList: {
      // 批次列表
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dateTypes: [
        {
          label: "认购日期",
          value: 1
        },
        {
          label: "计划签约",
          value: 2
        }
      ],
      date: "",
      queryData: {
        estateId: null,
        batch: "", // 批次
        orderType: 1, // 1认购列表 2 签约列表 ,
        keyword: "", // 订单/房源编号
        customerAuthId: null, //职业顾问Id
        channelName: "", // 渠道名称
        dateType: 1, //日期类型 ： 1 发起认购 2 预计签约 5 签约 6 下次回款 10 回款 ） 15 结佣 20 结佣完成 ,
        startDate: "",
        endDate: "",
        sortType: 0, // 0添加时间 1总溢价 2 认购日期  3 签约日期 4 回款日期 5 提交结佣日期 6 结佣日期
        sort: 1, // 0 asc 1 desc
        pageNum: 1,
        pageSize: 30
      },
      list: [],
      total: 0,
      channelList: [], // 渠道列表
      customerAuthList: [] // 置业顾问列表
    };
  },
  watch: {
    estateId: {
      immediate: true,
      handler(id) {
        // 楼盘id变化时，重新获取数据
        if (id) {
          this.queryData.estateId = id;
          this.getList();
          this.getCustomerAuth();
          this.getChannelList();
        }
      }
    }
  },
  methods: {
    // 进入订单详情
    toOrderDetail(id) {
      // 打开新浏览器tab
      this.$openWindow(`/distribution/appManage/signedOrder/${id}`);
    },
    search() {
      this.queryData.pageNum = 1;
      this.getList();
    },
    async getList() {
      let [res1, res2] = await Promise.all([
        getOrderList(this.queryData),
        getOrderListSum(this.queryData)
      ]);
      if (res1.list.length) {
        // 将统计数据追加到列表最后一列
        this.list = [...res1.list, { ...res2, summary: true }];
      } else {
        this.list = res1.list;
      }
      this.total = res1.total;
    },
    async getCustomerAuth() {
      // 置业顾问
      const res = await getOrderConditionCustomerauth({
        estateId: this.queryData.estateId,
        orderType: this.queryData.orderType //  1为认购 2 为签约
      });
      if (res) {
        this.customerAuthList = res;
      }
    },
    async getChannelList() {
      // 渠道
      const res = await getOrderConditionChannel({
        estateId: this.queryData.estateId,
        orderType: this.queryData.orderType //  1为认购 2 为签约
      });
      if (res) {
        this.channelList = res.filter(item => item);
      }
    },
    dateChange(val) {
      // 日期
      let [start, end] = val || [];
      this.queryData.startDate = start;
      this.queryData.endDate = end;
      this.search();
    },
    tableRowClassName({ row }) {
      // 合计行
      if (row.summary) {
        return "summary";
      }
      // 超期未签约，套红显示
      if (row.overdueDay < 0) {
        return "red";
      }
      // 已取消，置灰
      if (row.status == 100) {
        return "gray";
      }
    },
    sortChange({ prop, order }) {
      // 排序
      //sortType 0添加时间 1总溢价 2 认购日期  3 签约日期 4 回款日期 5 提交结佣日期 6 结佣日期
      //sort 0 asc 1 desc
      let obj = {
        overflowTotalAmount: 1,
        orderDateDesc: 2
      };
      let orderObj = {
        ascending: 0,
        descending: 1
      };
      this.queryData.sortType = obj[prop];
      this.queryData.sort = orderObj[order];
      this.getList();
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../_styles/house.scss";
.date-box {
  @include flex-ycb();
  ::v-deep .el-select {
    width: 105px;
    .el-input__inner {
      border-radius: 4px 0 0 4px;
    }
  }
  ::v-deep .el-date-editor {
    margin-left: -1px;
    width: 230px;
    border-radius: 0 4px 4px 0;
  }
}
::v-deep .el-table td {
  vertical-align: top;
}
</style>
