var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "tips"
  }, [_vm._v(" [温馨提示]“顾问”，即认购置业顾问；“底”，即销售底总价； “溢”，即溢价=认购价格-控制收款额；“率”，即总溢价率或毛利率=总溢价÷认购价格；“收”，即已确认收到的定金； ")]), _c('div', {
    staticClass: "filter-box mt20"
  }, [_c('div', {
    staticClass: "filter"
  }, [_c('el-input', {
    staticClass: "mr10 f-search-bar",
    staticStyle: {
      "width": "295px"
    },
    attrs: {
      "placeholder": "订单/房源编号、客户姓名/电话"
    },
    nativeOn: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      }
    },
    model: {
      value: _vm.queryData.keyword,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "keyword", $$v);
      },
      expression: "queryData.keyword"
    }
  }, [_c('el-button', {
    attrs: {
      "slot": "append"
    },
    on: {
      "click": _vm.search
    },
    slot: "append"
  }, [_vm._v("搜索")])], 1), _c('el-select', {
    staticClass: "mr10",
    attrs: {
      "placeholder": "批次",
      "clearable": ""
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.batch,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "batch", $$v);
      },
      expression: "queryData.batch"
    }
  }, _vm._l(_vm.batchList, function (item, index) {
    return _c('el-option', {
      key: index,
      attrs: {
        "value": item,
        "label": item
      },
      on: {
        "change": _vm.search
      }
    });
  }), 1), _c('el-select', {
    staticClass: "mr10",
    staticStyle: {
      "width": "135px"
    },
    attrs: {
      "placeholder": "渠道",
      "filterable": "",
      "clearable": ""
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.channelName,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "channelName", $$v);
      },
      expression: "queryData.channelName"
    }
  }, _vm._l(_vm.channelList, function (item, index) {
    return _c('el-option', {
      key: index,
      attrs: {
        "value": item.name,
        "label": item.name
      }
    });
  }), 1), _c('el-select', {
    staticClass: "mr10",
    staticStyle: {
      "width": "135px"
    },
    attrs: {
      "placeholder": "置业顾问",
      "clearable": ""
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.customerAuthId,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "customerAuthId", $$v);
      },
      expression: "queryData.customerAuthId"
    }
  }, _vm._l(_vm.customerAuthList, function (item, index) {
    return _c('el-option', {
      key: index,
      attrs: {
        "value": item.id,
        "label": item.name
      }
    });
  }), 1), _c('div', {
    staticClass: "date-box"
  }, [_c('el-select', {
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.dateType,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "dateType", $$v);
      },
      expression: "queryData.dateType"
    }
  }, _vm._l(_vm.dateTypes, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "value": item.value,
        "label": item.label
      }
    });
  }), 1), _c('el-date-picker', {
    attrs: {
      "type": "daterange",
      "range-separator": "-",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      "format": "yyyy-MM-dd",
      "value-format": "yyyy-MM-dd"
    },
    on: {
      "change": _vm.dateChange
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1)], 1), _c('div', {
    staticClass: "actions-btn"
  }, [_c('el-button', {
    attrs: {
      "icon": "iconfont icon-refresh"
    },
    on: {
      "click": _vm.getList
    }
  })], 1)]), _c('el-table', {
    staticClass: "mt15",
    attrs: {
      "data": _vm.list,
      "border": "",
      "row-class-name": _vm.tableRowClassName
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "orderCode",
      "label": "订单编号",
      "width": "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [row.summary ? [_c('div', {
          staticClass: "title"
        }, [_vm._v("合计")])] : [_c('div', {
          staticClass: "title"
        }, [_c('el-link', {
          attrs: {
            "type": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.toOrderDetail(row.id);
            }
          }
        }, [_vm._v(_vm._s(row.orderCode))])], 1), _c('p', [_vm._v("顾问 " + _vm._s(row.customerAuthName))])]];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "houseBuildingSurface",
      "label": "房源信息"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [row.summary ? [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(row.houseBuildingSurface) + "㎡")])] : [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(row.houseNumber))]), _c('p', [_vm._v(_vm._s(row.houseBuildingSurface) + "㎡")])]];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "houseSalesPrice",
      "label": "计划收款额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm._f("toThousands")(row.houseSalesPrice)))]), _c('p', [!row.summary ? _c('span', [_vm._v("底")]) : _vm._e(), _vm._v(" " + _vm._s(_vm._f("toThousands")(row.houseSalesReservePrice)) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "totalAmount",
      "label": "认购总额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm._f("toThousands")(row.totalAmount)))]), _c('p', [!row.summary ? _c('span', [_vm._v("溢")]) : _vm._e(), _c('span', {
          class: {
            red: row.overflowAmount < 0
          }
        }, [_vm._v(_vm._s(_vm._f("toThousands")(row.overflowAmount)))])])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "overflowTotalAmount",
      "label": "总溢价",
      "sortable": "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(" " + _vm._s(_vm._f("toThousands")(row.overflowTotalAmount)) + " ")]), _c('p', [!row.summary ? _c('span', [_vm._v("率")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.$calc.accMulti(row.overflowTotalRate, 100)) + "% ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "depositAmount",
      "label": "定金回款"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c('div', {
          staticClass: "title"
        }, [_c('span', {
          staticClass: "orange"
        }, [_vm._v(_vm._s(_vm._f("toThousands")(row.receivedAmount)))])]), row.transferedAmount && !row.summary ? _c('p', [_c('span', [_vm._v("转" + _vm._s(_vm._f("toThousands")(row.transferedAmount)) + " ")])]) : _vm._e(), [25, 100].includes(row.status) ? _c('p', [row.extendStatus !== 1 ? _c('span', {
          class: {
            red: !row.summary
          }
        }, [_vm._v(" 退" + _vm._s(_vm._f("toThousands")(row.refundAmount)) + " ")]) : _vm._e()]) : row.unReceiveAmount ? _c('p', [_vm._v(" 欠" + _vm._s(_vm._f("toThousands")(row.unReceiveAmount)) + " ")]) : row.status != 5 && !row.unReceiveAmount && !row.refundAmount && !row.transferedAmount && !row.summary ? _c('p', [_vm._v(" 已回款 ")]) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "customerName",
      "label": "认购客户"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(row.customerName))]), _c('p', [_vm._v(_vm._s(_vm._f("formatPhone")(row.customerPhone)))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "channelName",
      "label": "渠道信息"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [!row.summary ? [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(row.channelName || "自然到访"))]), _c('p', [_vm._v(_vm._s(row.agentUser))])] : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "orderDateDesc",
      "label": "认购日期",
      "sortable": "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [!row.summary ? [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(row.orderDateDesc))]), _c('p', [row.status == 1 ? _c('span', {
          class: {
            red: row.overdueDay < 0
          }
        }, [_vm._v(" 计划签约" + _vm._s(row.signDateDesc) + " ")]) : _vm._e(), row.status == 25 ? _c('span', {
          staticClass: "red"
        }, [_vm._v(" 退款中 ")]) : _vm._e(), row.status == 100 ? _c('span', [_vm._v(" 已取消(" + _vm._s(row.extendStatus == 1 ? "他签" : "已退款") + ") ")]) : _vm._e()])] : _vm._e()];
      }
    }])
  })], 1), _c('div', {
    staticClass: "footer-bar mt10"
  }, [_c('div', {
    staticClass: "action"
  }), _c('f-pagination', {
    attrs: {
      "page-num": _vm.queryData.pageNum,
      "page-size": _vm.queryData.pageSize,
      "total": _vm.total,
      "fixed": false
    },
    on: {
      "update:pageNum": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:page-num": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:pageSize": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "update:page-size": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "change": _vm.getList
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }